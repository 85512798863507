import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts"
import { fetchBarChartData } from "../utils/apis.js"
import { toast } from "react-toastify"
import Loader from "./loader.js"
import { getUniqueId } from "../utils/common-function.js"
import { Slider } from "@material-ui/core"
import DatePickerSlider from "../components/ui/data-picker-slider/DatePickerSlider.jsx"
import NewBarChart from "../components/dashboard/NewBarChart.jsx"
import { Button } from "@mui/material"
import { CustomToolTip, CustomXAxisTick } from "../utils/graphCustomeComponent.js"
import { useSelector } from "react-redux"

const CustomToolTipForBarChart = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload // Access the payload for the hovered bar

    // Filter subjects where value is greater than 0
    const subjectsWithValues = Object.keys(data).filter((subject) => {
      return (subject === "SQL" || subject === "Python" || subject === "Excel") && data[subject] > 0
    })

    // Helper function to render levels for a subject
    const renderLevels = (subject, levels) => {
      return Object.keys(levels).map((level) => <p key={`${subject}-level-${level}`}>{`${subject} - Level ${level}: ${levels[level]}`}</p>)
    }

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#1d1d1d",
          borderRadius: 5,
          padding: 4,
          color: "#e5e7eb",
        }}>
        <p className="label">{`${label}`}</p>
        {subjectsWithValues.map((subject, index) => (
          <div key={index}>
            {/*<p>{`${subject}: ${data[subject]}`}</p> {/* Display the subject value */}
            {renderLevels(subject, data[`${subject}_levels`])} {/* Display levels if they exist */}
          </div>
        ))}
      </div>
    )
  }
  return null
}

const DashboardStackedBarChart = ({ interval, metric, forPublic, filterParams, handleBarChartData }) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState("idle")
  const [selectedMode, setSetSelectedMode] = useState("timeseries")
  const [initialData, setIntitialData] = useState([])
  // const startData = useSelector((state) => state.userProgress.barChartData)

  // useEffect(() => {
  //   setIntitialData(startData)
  // }, [startData])

  //Helper functions

  const preprocessData2 = (rawData, interval, field) => {
    let groupedData = {}

    rawData.forEach((entry) => {
      const { date, tool } = entry
      const entryDate = new Date(date)

      // Determine the interval key and the actual date for sorting
      let intervalKey, sortDate
      if (interval === "Daily") {
        intervalKey = formatDateDaily(entryDate) // Format as 'Jul 29'
        sortDate = entryDate // Use the actual date for sorting
      } else if (interval === "Weekly") {
        const startOfWeek = getStartOfWeek(entryDate)
        const endOfWeek = getEndOfWeek(startOfWeek)
        intervalKey = formatDateWeekly(startOfWeek, endOfWeek) // Format as 'Jul 29 - Aug 6'
        sortDate = startOfWeek // Use the start of the week for sorting
      } else if (interval === "Monthly") {
        intervalKey = formatDateMonthly(entryDate) // Format as 'Sep 2024'
        sortDate = new Date(entryDate.getFullYear(), entryDate.getMonth(), 1) // Use the first day of the month for sorting
      }

      // Initialize the group if it doesn't exist yet
      if (!groupedData[intervalKey]) {
        groupedData[intervalKey] = {
          name: intervalKey, // Use formatted date/week/month as the name
          sortDate: sortDate, // Store the actual date for sorting later
          SQL: 0, // Placeholder for total data aggregation
          Python: 0,
          Excel: 0,
          SQL_levels: {}, // Store level data for tooltip
          Python_levels: {},
          Excel_levels: {},
        }
      }

      // Aggregate data for each tool (SQL, Python, Excel) across all levels
      Object.keys(tool).forEach((subject) => {
        const levels = tool[subject]

        // Sum the data for all levels of the subject (SQL, Python, Excel)
        Object.keys(levels).forEach((level) => {
          groupedData[intervalKey][subject] += levels[level][field] || 0 // Aggregate questionsSolved or rewardPoints

          // Store level data for use in the tooltip
          if (!groupedData[intervalKey][`${subject}_levels`][level]) {
            groupedData[intervalKey][`${subject}_levels`][level] = 0
          }
          groupedData[intervalKey][`${subject}_levels`][level] += levels[level][field] || 0
        })
      })
    })

    // Convert grouped data into an array and sort by the actual date for proper chronological order
    let finalData = Object.values(groupedData)
    finalData.sort((a, b) => a.sortDate - b.sortDate) // Sort by the actual date (sortDate)

    // Limit to the latest 30 records if daily is selected
    if (interval === "Daily" || interval === "Weekly") {
      finalData = finalData.slice(-13) // Get the last 30 entries
    }

    return finalData
  }

  // Helper function to format date for daily intervals as 'Jul 29'
  const formatDateDaily = (date) => {
    const options = { month: "short", day: "numeric" }
    return date.toLocaleDateString("en-US", options) // 'Jul 29'
  }

  // Helper function to format date for weekly intervals as 'Jul 29 - Aug 6'
  const formatDateWeekly = (startOfWeek, endOfWeek) => {
    const startOptions = { month: "short", day: "numeric" }
    const endOptions = { month: "short", day: "numeric" }
    const startStr = startOfWeek.toLocaleDateString("en-US", startOptions)
    const endStr = endOfWeek.toLocaleDateString("en-US", endOptions)
    return `${startStr} - ${endStr}` // 'Jul 29 - Aug 6'
  }

  // Helper function to format date for monthly intervals as 'Sep 2024'
  const formatDateMonthly = (date) => {
    const options = { month: "short", year: "numeric" }
    return date.toLocaleDateString("en-US", options) // 'Sep 2024'
  }

  // Helper function to get the start of the week for a given date (Sunday as the start)
  const getStartOfWeek = (date) => {
    const day = date.getDay()
    const diff = date.getDate() - day // Adjust when the day is Sunday (0)
    return new Date(date.setDate(diff))
  }

  // Helper function to get the end of the week based on the start of the week (Saturday)
  const getEndOfWeek = (startOfWeek) => {
    const endDate = new Date(startOfWeek)
    endDate.setDate(startOfWeek.getDate() + 6) // Move to the end of the week (Saturday)
    return endDate
  }

  const fetchDataForBarChart = async (filterParams) => {
    let uniqueId

    if (forPublic) uniqueId = getUniqueId(window.location.href)
    const response = await fetchBarChartData(uniqueId, filterParams)

    if (response.status === 200) {
      if (response.data.length > 0) {
        setIntitialData(response.data)
        handleBarChartData(response.data)
        const processedData = preprocessData2(response.data, interval, metric)
        setData(processedData)
        //console.log({ processedData, udpatedprocesseddata: preprocessData(response.data, interval, metric) })

        processedData.length == 0 ? setStatus("no_data") : setStatus("success")
      } else {
        setStatus("no_data")
      }
    } else {
      setStatus("failure")
      console.error("Failed to fetch data")
      toast.error("Unable to Fetch Your Progress!")
    }
  }

  useEffect(() => {
    const processedData = preprocessData2(initialData, interval, metric)
    setData(processedData)
  }, [metric, interval])

  useEffect(() => {
    fetchDataForBarChart(filterParams)
  }, [filterParams])

  const subjects = ["Python", "SQL", "Excel"] // Adjust based on your data
  const colors = {
    // More vibrant colors for bars
    SQL: ["#9f1239", "#fb7185"],
    Python: ["#86198f", "#e879f9"],
    Excel: ["#166534", "#4ade80"],
  }

  const [sliderValue, setSliderValue] = React.useState([0, data?.length - 1])

  const handleSliderValueChange = (event, newValue) => {
    setSliderValue(newValue)
  }

  useEffect(() => {
    setSliderValue([0, data.length - 1])
  }, [data])

  const renderGradients = () => (
    <defs>
      {subjects.map((subject, index) => (
        <linearGradient
          key={subject}
          id={`gradient-${subject}`}
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%">
          <stop
            offset="0%"
            stopColor={colors[subject][0] || "#000"}
          />
          <stop
            offset="100%"
            stopColor={colors[subject][1] || "#fff"}
          />
        </linearGradient>
      ))}
    </defs>
  )

  let content

  if (status === "idle") {
    content = <Loader />
  } else if (status === "no_data") {
    content = (
      <h1 className="text-sm text-center text-gray-400">
        No Data to Display <br></br>Solve Questions to see your Progress!
      </h1>
    )
  } else if (status === "failure") {
    content = <h1>Unable to Fetch Data Try Again!</h1>
  } else {
    content = (
      <>
        <div className="flex gap-3 mr-auto w-full items-center flex-wrap sm:justify-center justify-center md:justify-start mb-3">
          <Button
            variant="outlined"
            size="small"
            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${selectedMode === "timeseries" ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
            onClick={() => setSetSelectedMode("timeseries")}>
            Time Series
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${selectedMode === "levelwise" ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
            onClick={() => setSetSelectedMode("levelwise")}>
            Level Wise
          </Button>

          {selectedMode === "timeseries" && data?.length > 1 && (
            <div className="relative flex lg:gap-0 m-0 sm:m-0 md:px-4 my-1 items-center flex-col group">
              <span className="absolute top-7 text-gray-400 text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-1/2 transform -translate-x-1/2">Select Duration</span>

              <DatePickerSlider
                sliderData={data}
                onChange={handleSliderValueChange}
                value={sliderValue}
              />
            </div>
          )}
        </div>

        {selectedMode === "timeseries" ? (
          <>
            <div style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }} className="p-2">
              {/* Enable horizontal scrolling */}
              <div
              // style={{ width: `${data.length * 50}px` }}
              >
                <ResponsiveContainer
                  width="100%"
                  height={250}>
                  <BarChart
                    data={data.slice(sliderValue[0], sliderValue[1] + 1)}>
                    {renderGradients()}
                    <XAxis
                      dataKey="name"
                      axisLine={{ stroke: "#94a3b8" }}
                      tickLine={{ stroke: "#94a3b8" }}
                      tick={<CustomXAxisTick />}
                      interval={0}
                      angle={-20}
                      textAnchor="middle"
                      height={50}
                      fontSize={12}
                      dy={10}
                    />
                    <YAxis
                      axisLine={{ stroke: "#94a3b8" }}
                      tickLine={{ stroke: "#94a3b8" }}
                      tick={{ fill: "#94a3b8" }}
                      fontSize={12}
                      allowDecimals={false}

                      label={{
                        value: "Questions Solved",
                        angle: -90,
                        fill: "#94a3b8",
                        position: "insideLeft",
                        dy:70,
                        offset:20
                      }}

                    />
                    <Tooltip
                      content={<CustomToolTipForBarChart />}
                      cursor={{ fill: "#374151", opacity: ".5" }}
                    />
                    {subjects.map((subject) => (
                      <Bar
                        key={subject}
                        dataKey={subject} // Data key represents the aggregated value for the subject
                        stackId={subject} // Stack bars for each subject
                        fill={`url(#gradient-${subject})`}
                        barSize={16}
                        radius={[6, 6, 0, 0]} // Rounded top corners
                        isAnimationActive={false}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </>
        ) : (
          <NewBarChart data={data} />
        )}
      </>
    )
  }

  return <div className="h-[310px]">{content}</div>
}

export default DashboardStackedBarChart
