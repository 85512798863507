import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { fetchPieChartData } from "../utils/apis.js"
import { toast } from "react-toastify"
import Loader from "../assets/loader"
import { getUniqueId } from "../utils/common-function.js"
import { CustomLegend } from '../utils/graphCustomeComponent.js'

const DashboardPieChart = ({ metric, forPublic, filterParams }) => {
  const [data, setData] = useState([])
  const [status, setStatus] = useState("idle")

  const CustomPieTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#232627",
            borderRadius: 5,
            padding: 5,
          }}>
          <p className="label">{`${payload[0].name}`}</p>
          <p
            className="value"
            style={{ fontWeight: "bold" }}>{`${metric === "questionsSolved" ? "Questions  Solved " : "Reward Points"}: ${payload[0].value}`}</p>
        </div>
      )
    }

    return null
  }


  const fetchData = async () => {
    // Replace this URL with your actual endpoint
    let uniqueId
    if (forPublic) uniqueId = getUniqueId(window.location.href)
    const response = await fetchPieChartData(uniqueId, filterParams)

    if (response.status === 200) {
      if (response.data.length > 0) {
        setData(response.data)
      }
      response.data.length == 0 ? setStatus("no_data") : setStatus("success")
    } else {
      setStatus("failure")
      console.error("Failed to fetch data")
      toast.error("Unable to Fetch Your Progress!")
    }
  }

  useEffect(() => {
    const fetchAsyncPieData = async () => {
      await fetchData(filterParams)
    }
    fetchAsyncPieData()
  }, [filterParams])

  let content

  if (status === "idle") {
    content = <Loader />
  } else if (status === "no_data") {
    content = (
      <h1 className="text-center text-sm text-gray-400">
        No Data to Display <br></br> Solve Questions to see your Progress!
      </h1>
    )
  } else if (status === "failure") {
    content = <h1>Unable to Fetch Data Try Again!</h1>
  } else {
    content = (
      <>
        {/* <h6 className="text-center text-xl font-semibold p-4">Overall Progress</h6> */}
        <ResponsiveContainer
          width="100%"
          height={250}>
          <PieChart>
            <defs>
              <linearGradient
                id="SQL"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "#9f1239", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#fb7185", stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="Python"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "#86198f", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#e879f9", stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="Excel"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "#166534", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#4ade80", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={90}
              dataKey={metric}
              paddingAngle={3}
              label>
              {data.map((entry, index) => {
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={`url(#${entry.name})`}
                    stroke=""
                  />
                )
              })}
            </Pie>
            <Tooltip content={<CustomPieTooltip />} />
            <Legend
              iconType="circle"
              content={<CustomLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
            />
          </PieChart>
        </ResponsiveContainer>
      </>
    )
  }

  return <div className="h-[310px] flex justify-center items-center">{content}</div>
}

export default DashboardPieChart
