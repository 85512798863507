import React from 'react'

const BoxPageLoader = () => {
    return (
        <div>
            <svg viewBox="0 0 100 100" className='w-[150px] h-[150px]'>
                <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6">
                    <path d="M 50 21 L 79 40 L 50 60 L 21 40 Z">
                        <animate
                            attributeName="stroke"
                            values="rgba(255,255,255,1); rgba(100,100,100,0)"
                            dur="2s"
                            repeatCount="indefinite" />
                    </path>
                    <path d="M 50 40 L 79 59 L 50 79 L 21 59 Z" />
                    <path d="M 50 59 L 79 78 L 50 98 L 21 78 Z">
                        <animate
                            attributeName="stroke"
                            values="rgba(100,100,100,0); rgba(255,255,255,1)"
                            dur="2s"
                            repeatCount="indefinite" />
                    </path>
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="translate"
                        values="0 0; 0 -19"
                        dur="2s"
                        repeatCount="indefinite" />
                </g>
            </svg>
        </div>
    )
}

export default BoxPageLoader