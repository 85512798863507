import React, { useState, useEffect } from "react"
import CommonHeader from "../assets/common-header"
import { fetchUserDetails } from "../redux/actions/user"
import { useSelector, useDispatch } from "react-redux"
import Loader from "./../assets/loader" // Import your Loader component
import { toast } from "react-toastify"
import { sendVerificationEmail, verifyOTP } from "../utils/apis.js"
import { isEmailValid } from "../utils/common-function"
import { useNavigate } from "react-router-dom"
import { toastConfig } from "../utils/constants.js"
import { Button } from "@mui/material"
import { Send } from "@mui/icons-material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

function VerifyEmail() {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const { userDetails } = useSelector((state) => ({
    userDetails: state.user.userDetails,
  }))

  const [otpSent, setOtpSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState("")

  useEffect(() => {
    setLoading(true)
    dispatch(fetchUserDetails())
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }, [dispatch])

  const handleSendOtp = async () => {
    const { email } = userDetails
    if (!isEmailValid(email)) {
      toast.error("Invalid email", toastConfig)
    }
    try {
      const response = await sendVerificationEmail({ email })
      if (response.status === 200) {
        toast.success("Verification Code Sent", toastConfig)
        setOtpSent(true)
      }
    } catch (e) {
      toast.error("Error while sending verification code", toastConfig)
      console.log(e)
    }
  }

  const verifyOtp = async () => {
    const { email } = userDetails
    const userOtp = otp

    if (!userOtp) {
      toast.error("Please enter Verification Code", toastConfig)
      return
    }

    try {
      const response = await verifyOTP({ email, userOtp })

      if (response.status === 200) {
        toast.success("Verification Successful", toastConfig)
        const token = response.data.token
        localStorage.setItem("usertoken", token)
        window.location.href = "/question"
      } else if (response.status === 400) {
        toast.error("Invalid Verification Code", toastConfig)
      } else if (response.status === 401) {
        toast.error("Unauthorized, Token Expired, Please login again", toastConfig)
      }
    } catch (e) {
      toast.error("Invalid Verification Code", toastConfig)
      console.log(e)
    } finally {
      setOtp("")
    }
  }

  if (loading) {
    return <Loader /> // Display the Loader component while loading
  }

  return (
    <>
      <CommonHeader />
      <div className="flex flex-col items-center justify-start h-[calc(100vh-50px)] bg-[#232627] text-[#e5e7eb]">
        <div className="myCard p-6 !bg-[#1f2021]">
          <h2 className="text-base">Verify Your Email to Continue</h2>
          <p className="my-2 text-sm leading-6 text-gray-400">
            An OTP will be sent to your email <span className="text-yellow-400">{userDetails && userDetails.email}</span>
          </p>

          {userDetails && !userDetails.isVerified && !otpSent && (
            <Button
              variant="outlined"
              onClick={handleSendOtp}
              endIcon={<Send />}
              className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize  !text-gray-400 !border-gray-600">
              Send OTP
            </Button>
          )}
          {userDetails && userDetails.isVerified && (
            <Button
              variant="outlined"
              disabled
              endIcon={
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="pl-1"
                  color={"text-green-400"}
                />
              }
              className="!font-medium !text-green-400  !border-green-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize ">
              Verified Account
            </Button>
          )}
          {otpSent && (
            <div className="mt-4">
              <input
                type="text"
                placeholder="Enter OTP"
                className="input"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button
                variant="outlined"
                className="!my-2 !font-medium !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-green-400  !border-green-600 !border-[0.5px]"
                onClick={verifyOtp}>
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default VerifyEmail
