import React, { useState } from "react"
import { TablePagination } from "@mui/material"

const SingleTable = ({ data, tableName }) => {
  const [page, setPage] = useState(0) // MUI TablePagination starts at 0
  const [rowsPerPage, setRowsPerPage] = useState(10) // Default rows per page

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset to the first page whenever rows per page change
  }

  // Slicing the data to show only rows for the current page
  const paginatedRows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const headers = data.length > 0 ? Object.keys(data[0]) : []

  return (
    <div className="flex flex-col h-full">
      <div className="overflow-y-auto flex-grow">
        <table className="leading-normal mb-2">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="border-b-2 bg-[#232627] text-left text-xs font-medium text-gray-200 p-2">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedRows.map((item, rowIndex) => (
              <tr
                key={rowIndex}
                className={`border-b border-gray-200 ${rowIndex % 2 === 0 ? "bg-gray-100" : ""}`}>
                {headers.map((header, index) => (
                  <td
                    key={index}
                    className="text-sm text-gray-400 p-2">
                    {item[header]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination always visible at the bottom */}
      <div className="sticky bottom-0 bg-[#232627] flex items-center justify-end text-gray-200">
        <TablePagination
          component="div"
          sx={{
            color: "#e5e7eb",
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": {
              color: "#e5e7eb",
            },
            "& .MuiTablePagination-toolbar": {
              padding: "0px",
              height: "25px !important",
            },
            ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
              height: "40px !important",
            },
            "& .MuiTablePagination-select": {
              color: "#e5e7eb",
              backgroundColor: "#232627",
              padding: "4px",
              borderRadius: "5px",
            },
            "& .MuiSvgIcon-root": {
              color: "#e5e7eb",
            },
            "& .MuiTablePagination-actions button": {
              color: "#e5e7eb",
              padding: "0px",
            },
          }}
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  )
}

const Table = ({ tablesData, tables }) => {
  return (
    <div className="flex flex-col h-full">
      <SingleTable
        data={tablesData}
        key={Math.random()}
        tableName={tables}
      />
    </div>
  )
}

export default React.memo(Table)
