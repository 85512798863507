import React, { useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, TextField, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { Box, DialogContentText, FormHelperText, Modal as MUIModal } from "@mui/material"

const theme = createTheme({
  palette: {
    type: "dark", // Enables dark mode globally
    primary: {
      main: "#e5e7eb",
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        color: "#e5e7eb !important", // Ensures checkbox color is white
        "&$checked": {
          color: "#e5e7eb !important",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#ff8383 !important", // Helper text color
      },
    },
    MuiDialog: {
      root: {
        opacity: "95%",
        backdropFilter: "blur(4px)",
      },
    },
  },
})

// Custom Styles for Dark Theme
const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#111827", // Dark background for the dialog
    color: "#e5e7eb",
    opacity: "70%", // Text color for better contrast
  },
  checkboxLabel: {
    color: "#e5e7eb !important", // Ensure checkbox label is white
  },
  textField: {
    "& .MuiInputBase-root": {
      color: "#e5e7eb !important", // Input text color
    },
    "& .MuiFormLabel-root": {
      color: "#e5e7eb !important", // Label text color
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#e5e7eb !important", // Border color for the input
    },
    "& .MuiFormHelperText-root": {
      color: "#e5e7eb !important", // Helper text color to white
    },
  },
  actions: {
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  submitButton: {
    color: "#e5e7eb",
    backgroundColor: "#16a34a", // Primary color for Submit button
    "&:hover": {
      backgroundColor: "#16a34a",
    },
  },
  cancelButton: {
    color: "#e5e7eb",
    borderColor: "#e5e7eb",
  },
  checkbox: {
    accentColor: "#e5e7eb",
  },
}))

const RaiseForReviewDialog = ({ open, onClose, answer, onSubmit }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
  }
  const classes = useStyles()

  // Array of reason labels
  const reasonLabels = ["Unclear problem statement", "Incorrect evaluation of submitted solution", "Inadequate/unclear hints", "Logically incorrect question", "Invalid Answer format"]

  const [reasons, setReasons] = useState([])
  const [feedbackText, setFeedbackText] = useState("")
  const [includeSolution, setIncludeSolution] = useState(false)

  const handleCheckboxChange = (event) => {
    const reason = event.target.name
    setReasons((prevReasons) => (event.target.checked ? [...prevReasons, reason] : prevReasons.filter((r) => r !== reason)))
  }

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value)
  }

  const handleSubmit = () => {
    onSubmit(reasons, feedbackText, includeSolution)
    onClose()
  }

  return (
    <ThemeProvider theme={theme}>
      {/* <MUIModal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent backdrop-blur-sm">
        <Box
          sx={style}
          className="border-l !border-b border-gray-600 bg-gray-900 bg-opacity-70 rounded-lg">
          <div className="flex justify-center flex-col p-4 items-center"> */}
      <Dialog
        open={open}
        onClose={onClose}
        classes={{ paper: classes.dialog }}>
        <DialogTitle>Raise for Manual Review</DialogTitle>
        <FormHelperText
          classes={classes.textField}
          sx={{ paddingLeft: "2rem" }}>
          Select at least one option
        </FormHelperText>
        <DialogContent>
          <FormGroup>
            {reasonLabels.map((label) => (
              <FormControlLabel
                key={label}
                control={
                  <Checkbox
                    sx={{
                      color: "#fefefe !important",
                      "&.Mui-checked": {
                        color: "#fefefe !important",
                      },
                    }}
                    checked={reasons.includes(label)}
                    onChange={handleCheckboxChange}
                    name={label}
                  />
                }
                label={label}
                classes={{ label: classes.checkboxLabel }}
              />
            ))}
          </FormGroup>
          <TextField
            label="Additional Details (Optional, max 200 characters)"
            multiline
            rows={4}
            fullWidth
            value={feedbackText}
            onChange={handleFeedbackChange}
            helperText={`${feedbackText.length}/200 characters`}
            inputProps={{ maxLength: 200 }}
            className={classes.textField}
            sx={{ marginTop: "1.5rem" }}
          />
          <FormControlLabel
            key="includeSolution"
            control={
              <Checkbox
                sx={{
                  color: "#fefefe !important",
                  "&.Mui-checked": {
                    color: "#fefefe !important",
                  },
                }}
                checked={includeSolution}
                onChange={() => setIncludeSolution(!includeSolution)}
                name={"Include solution"}
              />
            }
            label={"Include solution"}
            classes={{ label: classes.checkboxLabel }}
          />
          <FormHelperText> Please include valid soution, if you wish to include your solution</FormHelperText>
          {includeSolution && answer.length === 0 && <FormHelperText>Kindly type your solution, before raising for review</FormHelperText>}
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="outlined"
            className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={classes.submitButton}>
            Raise for Review
          </Button>
        </DialogActions>
      </Dialog>
      {/* </div>
        </Box>
      </MUIModal> */}
    </ThemeProvider>
  )
}

export default RaiseForReviewDialog
