import React from 'react'
import './rewardwithoutanimation.css'

const RewardCoinWithoutAnimation = () => {
    return (
        <div className='coinstatic'>
            <div className='front'>
                <div className='star'></div>
                <span className='currency !font-extrabold !-m-1'>M</span>
                <div className='shapes'>
                    <div className='shape_l'></div>
                    <div className='shape_r'></div>
                </div>
            </div>
        </div>
    )
}

export default RewardCoinWithoutAnimation