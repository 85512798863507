import React, { useEffect, useState, useRef } from "react"

import { makeStyles } from "@material-ui/core/styles"
import Filter from "../assets/filter"
import Modal from "../assets/modal"
import { SUBJECTS } from "../utils/constants"
import { Avatar, Badge, Button, ButtonBase, IconButton, Menu, MenuItem, MenuList, Tooltip } from "@mui/material"
import { marmaOnlyLogo, titleBarLogo } from "../assets/image_files"
import { useSelector, useDispatch } from "react-redux"
import { getQuestionsAttemptedByLevel, getLastAttemptedQuestionId } from "../utils/apis"
import { fetchQuestionHistory } from "../redux/actions/questionhistory"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import Loader from "../assets/loader"
import QuestionList from "../assets/question-list"
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded"
import WestSharpIcon from "@mui/icons-material/WestSharp"
import EastSharpIcon from "@mui/icons-material/EastSharp"
import Badges from "../assets/badges"
import { Link, useNavigate } from "react-router-dom"
import { AllInclusive, ExpandMoreOutlined, LockOpen, Map, SubjectOutlined } from "@mui/icons-material"
import { AdjustmentsIcon, LockOpenIcon } from "@heroicons/react/outline"
import Dropdown from "./ui/Dropdown"
import PremiumButton from "./ui/Premiumbutton"
import { LoadingButton } from "@mui/lab"
import RewardCoinWithoutAnimation from "./RewardPoint/RewardCoinWithoutAnimation"

const useStyles = makeStyles((theme) => ({
  rootSlider: {
    color: "#326143",
    alignSelf: "flex-end",
    height: "50px",
  },
  levelSlider: {
    width: "520px",
    height: "18px",
    color: "#326143",
    alignSelf: "flex-end",
  },
  search: {
    width: "50vw",
    color: "#fefefe",
    marginLeft: "",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  solutionButton: {
    background: "#2cbb62",
    color: "#fefefe",
    borderRadius: "8px",
  },
  manualReviewButton: {
    marginRight: "9px",
    padding: "6px 8px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    backgroundColor: "#fefefe",
    borderRadius: "8px",
    color: "#141718",
  },
  subjectSelect: {
    minWidth: "120px",
    color: "#f2f2f2",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D1D1",
      },
      "&:hover fieldset": {
        borderColor: "#D1D1D1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D1D1D1",
      },
    },
  },
  headerDropdownContainer: {
    position: "absolute",
    zIndex: "10000",
    top: "3.1rem",
    right: "1.25rem",
    //backgroundColor: "#232627",
    //border: "1px solid #FEFEFE1A",
    borderRadius: "18px",
    //padding: "1rem",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    overflowY: "auto",
    color: "#fefefe",
    marginTop: "1px",
    fontSize: "14px",
  },
}))

const filterButtonStyle = {
  padding: "0.2rem",
  border: "1px solid #FEFEFE1A",
  cursor: "pointer",
  backgroundColor: "#232627",
  color: "white",
}

const Header = ({ levels, primaryTool, selectedLevel, onChangeSubject, onChangeTechStack, onChangeLevel, onSubmit, isQuestionFetched, searchFilterList, onSearchFilter, selectedIndustry, selectedTopic, questionMeta, handleQuesSwitch, handleSwitchtoExactQues, stopTimer, startTimer }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("")
  const [submittedKey, onSetSubKey] = useState("")
  const [pendingTechStack, setPendingTechStack] = useState("")
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [userSearch, setSearch] = useState("")
  const classes = useStyles()
  const [levelData, setLevelData] = useState([])
  const [prevQue, setPrevQue] = useState(-1)
  const [historyVisible, setHistory] = useState(false)
  const [showDropDown, setShowDropdown] = useState(false)
  const [handleBadge, setHandleBagde] = useState(selectedTopic.length > 0)

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const questionHistory = useSelector((state) => state.questionHistory.questionHistory)
  const reduxUser = useSelector((state) => state.user.userDetails)
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const dropdownRef = useRef(null)
  const headerDropdownRef = useRef(null)
  const questionListRef = useRef(null)

  const getSolvedQuestionsCountByLevel = (questionsData, tool) => {
    return questionsData.reduce((acc, question) => {
      if (question.ToolSelected === tool && question.isAnswerAttempted && question.isAnswerCorrect) {
        const level = question.Level[tool] // Get the level for the given tool
        acc[level] = (acc[level] || 0) + 1
      }
      return acc
    }, {})
  }

  useEffect(() => {
    if (questionMeta && !questionMeta.rewardPoints) {
      if (historyVisible) stopTimer()
      else startTimer()
    }
  }, [historyVisible])

  useEffect(() => {
    if (selectedTopic.length === 0) {
      setHandleBagde(false)
    }
  }, [selectedTopic])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (questionListRef.current && !questionListRef.current.contains(event.target)) {
        setHistory(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [questionListRef])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsFilterVisible(false) // Close dropdown if clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerDropdownRef.current && !headerDropdownRef.current.contains(event.target)) {
        setShowDropdown(false) // Close dropdown if clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [headerDropdownRef])

  useEffect(() => {
    dispatch(fetchUserMetrics())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchQuestionHistory())
  }, [dispatch])

  useEffect(() => {
    if (questionHistory && questionHistory.length > 0 && primaryTool != null) {
      const solvedQuestionsByLevel = getSolvedQuestionsCountByLevel(questionHistory, primaryTool)
      setLevelData(solvedQuestionsByLevel)
    }
  }, [questionHistory])

  const confirmUpdate = async (confirm) => {
    setShowConfirmationModal(false)
    if (confirm) {
      if (submittedKey) {
        onSubmit(submittedKey)
        onSetSubKey("")
      }
      if (pendingTechStack) {
        onChangeTechStack(pendingTechStack)
        setPendingTechStack("")
      }
    } else {
      onSetSubKey("")
      setPendingTechStack("")
    }
  }

  const handleSubjectChange = (event) => {
    if (event.target.value !== primaryTool) onChangeSubject(event.target.value)
  }

  const handleToggleFilter = () => {
    setIsFilterVisible(!isFilterVisible)
  }

  const onSelectFilter = (v) => {
    handleToggleFilter()
    onSearchFilter(v)
  }
  const logout = () => {
    localStorage.removeItem("usertoken") // Assuming the JWT token is stored in localStorage
    localStorage.removeItem("hasShownFreeArenaPopup")
    localStorage.removeItem("refreshToken")
    window.location.reload()
  }

  const switchNext = () => {
    handleQuesSwitch(true, false)
  }
  const SwitchPrevious = () => {
    handleQuesSwitch(false, true)
  }
  const handleQuestionsHistory = (questionId, toolName, level) => {
    console.log({ questionId, toolName, level })
    handleSwitchtoExactQues(questionId, toolName, level)
    //getQuestionMetaData(false, false, questionId)
  }

  // if (questionHistory.length === 0)
  //   return (
  //     <>
  //       <LoadingButton
  //         size="small"
  //         style={{
  //           fontFamily: "inherit",
  //           //border: "1px solid #4B5563", // Gray border color
  //           color: "#6B7280", // Gray text color (when not loading)
  //           "--loading-color": "#9CA3AF", // Custom property for loader color
  //         }}
  //         loading
  //         classes={{
  //           loading: "loading-custom-color",
  //         }}
  //         className="!me-2 !text-xs "></LoadingButton>
  //     </>
  //   )

  return showConfirmationModal ? (
    <Modal
      isOpen={showConfirmationModal}
      onClose={() => setShowConfirmationModal(false)}
      title="Confirm Action">
      <p className="text-gray-400 text-sm mt-2">{confirmationModalMessage}</p>
      <div className="flex gap-2 mt-4">
        <Button
          variant="contained"
          size="small"
          className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
          onClick={() => confirmUpdate(true)}>
          Yes
        </Button>
        <Button
          variant="outlined"
          size="small"
          className="!shadow-none !text-gray-200 !border !border-gray-600"
          onClick={() => confirmUpdate(false)}>
          No
        </Button>
      </div>
    </Modal>
  ) : (
    <>
      <div className="flex flex-col h-[90px] fixed top-0  bg-[#232627] w-full z-[999]">
        <div className="flex flex-row justify-between items-center my-[0.125rem] px-[20px]">
          <div className="flex flex-row items-center justify-start">
            <img
              src={marmaOnlyLogo}
              alt="Marma AI"
              className="h-6 object-fit bg-transparent mr-3"
              onClick={() => navigate("/home")}
            />
            <Tooltip
              title="Switch to Previous Question"
              placement="bottom-start">
              <button
                onClick={SwitchPrevious}
                disabled={prevQue === 0 || questionMeta?.initialQuestion}>
                {" "}
                <WestSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />
              </button>
            </Tooltip>
            <Tooltip
              title="Switch to Next Question"
              placement="bottom-start">
              <button onClick={switchNext}>
                {" "}
                <EastSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />{" "}
              </button>
            </Tooltip>
            <div
              // className={classes.search}
              ref={dropdownRef}
              className="w-[400px]">
              <div className="px-4 bg-[#343839] h-[1.75rem] rounded-[8px] flex flex-row w-full items-center">
                <Badge
                  color="primary"
                  variant="dot"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#D0AA45",
                    },
                  }}
                  invisible={!handleBadge}>
                  <FilterListRoundedIcon sx={{ color: "#989898", height: "18px", width: "18px" }} />
                </Badge>
                <input
                  type="search"
                  value={userSearch}
                  onFocus={() => {
                    setIsFilterVisible(true)
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                  className="px-5 placeholder-gray-400 w-full bg-[#343839] focus:outline-none text-sm text-gray-400"
                  placeholder={handleBadge ? "Filter On" : "Filter"}
                />
              </div>
              {isFilterVisible && (
                <Filter
                  topics={userSearch === "" ? searchFilterList.functions : searchFilterList.functions.filter((item) => item.name.toLowerCase().includes(userSearch.toLowerCase()))}
                  industries={userSearch === "" ? searchFilterList.industries : searchFilterList.industries.filter((item) => item.name.toLowerCase().includes(userSearch.toLowerCase()))}
                  selectedTopic={selectedTopic}
                  selectedIndustry={selectedIndustry}
                  onSubmit={onSelectFilter}
                  handleShowBadge={(val) => setHandleBagde(val)}
                />
              )}
            </div>
          </div>

          <nav className="navigation flex items-center justify-evenly">
            {/* badges */}
            <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-0.5 rounded flex gap-3 justify-between items-center cursor-default">
              <Tooltip
                placement="bottom-start"
                arrow
                title="Reward Points">
                {userMetrics ? (
                  <p className="inline-flex text-sm gap-1 justify-center items-center">
                    {" "}
                    <RewardCoinWithoutAnimation />
                    {userMetrics.totalRewardPoints}
                  </p>
                ) : (
                  ""
                )}
              </Tooltip>

              <Tooltip
                placement="bottom-start"
                arrow
                title="Current Level">
                <p className="text-sm"> Level {questionMeta?.Level[primaryTool]}</p>
              </Tooltip>

              {userMetrics?.badges?.length !== 0 && <Badges badges={userMetrics?.badges} />}
            </div>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/arenas">Arenas</Link>
            <div ref={headerDropdownRef}>
              {reduxUser ? (
                <Avatar
                  onClick={() => setShowDropdown(!showDropDown)}
                  className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer">
                  {" "}
                  {reduxUser.name.split(" ")[0][0]}
                </Avatar>
              ) : (
                <Avatar
                  onClick={() => setShowDropdown(!showDropDown)}
                  className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer"
                  src={titleBarLogo}>
                  {" "}
                </Avatar>
              )}
              {showDropDown && (
                <div className={`${classes.headerDropdownContainer} shiny-border`}>
                  <ul className="list-none myCard space-y-2 p-6">
                    <li>
                      <Link to="/profile/notifications">Inbox </Link>
                    </li>
                    <li>
                      <Link to="/profile/basic-info">Profile</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQs</Link>
                    </li>
                    <li>
                      <button
                        className="text-[#989898] hover:text-blue-500 m-0 p-0"
                        onClick={logout}>
                        Logout
                      </button>{" "}
                      {/* Use a button for logout */}
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </nav>
        </div>
        <div className="flex flex-row justify-start items-center my-2 px-[10px]">
          {/* <Tooltip
            title="Subjects/Tools"
            placement="bottom"
            arrow>
            <div className={classes.subjectSelect}>
              <select
                id="subject-select"
                value={primaryTool}
                onChange={handleSubjectChange}
                className="drop-down-container">
                {SUBJECTS.map((subject) => (
                  <option
                    value={subject}
                    key={subject}
                    className="p-2 text-pretty">
                    {subject}
                  </option>
                ))}
              </select>
            </div>
          </Tooltip> */}

          <div
            id="subject-dropdown"
            className="me-3 ms-3">
            <Dropdown
              title={primaryTool || "Tools"}
              tooltip={`Primary tool selected for the situation is ${primaryTool}`}
              icon={<SubjectOutlined />}
              children={SUBJECTS.map((subject) => (
                <MenuItem
                  key={subject}
                  onClick={() => {
                    subject !== primaryTool && onChangeSubject(subject)
                  }}
                  className="!text-gray-200 !font-light !text-xs">
                  {subject}
                </MenuItem>
              ))}
            />
          </div>

          <div id="level-dropdown">
            <Dropdown
              title={questionMeta?.Level?.[primaryTool] ? "Level " + questionMeta.Level[primaryTool] : "loading..."}
              tooltip={`Choose difficulty level here`}
              icon={<AdjustmentsIcon className="h-5 w-5 text-gray-400" />}
              children={
                levelData.length === 0 ? (
                  <>
                    {levels.map((item) => (
                      <MenuItem
                        className={`!text-gray-200 !font-light !text-xs' ${selectedLevel === item ? "border-[2px] font-bold" : ""} `}
                        onClick={() => item !== selectedLevel && onChangeLevel(item)}
                        key={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </>
                ) : (
                  <>
                    {levels.map((item) => {
                      // Calculate fill percentage based on the index
                      const fillPercentage = levelData[`${item}`] ? Math.floor((parseInt(levelData[`${item}`]) / 50) * 100) : 0
                      return (
                        <MenuItem
                          // onClick={handleClose}
                          className="flex gap-3"
                          onClick={() => item !== questionMeta?.Level[primaryTool] && onChangeLevel(item)}
                          key={item}>
                          <button className="p-0 text-gray-200 font-light text-xs w-[1rem]">{item}</button>
                          <div class="w-full rounded-full h-2.5 bg-gray-700">
                            <div
                              class="h-2.5 rounded-full"
                              style={{
                                width: fillPercentage, // Change this dynamically as needed
                                background: "linear-gradient(to right, #4caf50, #15803d 60%, transparent 100%)",
                                backgroundColor: fillPercentage === 100 ? "#15803d" : "", // Apply green-700 color if progress is 100
                              }}></div>
                          </div>
                        </MenuItem>
                      )
                    })}
                  </>
                )
              }
            />
          </div>

          {questionMeta?.Level?.[primaryTool] ? (
            <Tooltip
              title={`Your progress for ${primaryTool} level ${questionMeta?.Level[primaryTool]}`}
              placement="bottom"
              arrow>
              <div className="w-[100px] ms-3 rounded-full h-2.5 bg-gray-700">
                <div
                  className="h-2.5 rounded-full"
                  style={{
                    width: levelData[`${questionMeta?.Level[primaryTool]}`] ? Math.floor((parseInt(levelData[`${questionMeta?.Level[primaryTool]}`]) / 50) * 100) : 0,
                    background: "linear-gradient(to right, #4caf50, #15803d 60%, transparent 100%)",
                    backgroundColor: levelData[`${questionMeta?.Level[primaryTool]}`] && Math.floor((parseInt(levelData[`${questionMeta?.Level[primaryTool]}`]) / 50) * 100) === 100 ? "#15803d" : "",
                  }}></div>
              </div>
            </Tooltip>
          ) : (
            <div
              role="status"
              className="max-w-sm animate-pulse">
              <div className="w-[100px] ms-3 rounded-full h-2.5 bg-gray-700"></div>
            </div>
          )}

          <div className="flex-row-container ml-auto gap-2">
            {reduxUser && !reduxUser.subscription.isActive && <PremiumButton questionsSeen={questionHistory.length} />}
            {reduxUser && reduxUser.subscription.isActive && (
              <Button
                variant="outlined"
                className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200"
                onClick={() => navigate("/pricing")}
                startIcon={<LockOpenIcon className="!text-base" />}
                style={{
                  fontFamily: "inherit",
                  border: "0.5px solid #9ca3af2a",
                  cursor: "pointer",
                  transition: "background 0.6s ease",
                }}>
                Premium
                <span className="!text-xs !text-gray-300 !bg-[#343839] !rounded !capitalize px-2 py-0.5">
                  <AllInclusive className="!text-base" />
                </span>
              </Button>
            )}
            {/* <Badge
              badgeContent={questionHistory.filter((question) => question.isAnswerCorrect).length}
              color="primary"
              className="!ml-auto history-button !justify-self-end me-3"
              sx={{
                "& .MuiBadge-badge": {
                  color: "#e5e7eb",
                  backgroundColor: "#4b5563",
                },
              }}> */}
              <Tooltip
                placement="bottom"
                title="Click to See your history"
                arrow>
                <Button
                  onClick={() => setHistory(!historyVisible)}
                  style={{ ...filterButtonStyle, fontFamily: "inherit" }}
                  disabled={questionHistory.filter((question) => question.isAnswerAttempted).length === 0}
                  className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5">
                  <Map className="!text-base !text-gray-400" /> Journey 
                  <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">{questionHistory.filter((question) => question.isAnswerCorrect).length}</span>
                </Button>
              </Tooltip>
            {/* </Badge> */}

            {/* <Tooltip
            placement="bottom"
            title="Click to See your journey"
            arrow>
            <Button
              onClick={() => setHistory(!historyVisible)}
              style={{ ...filterButtonStyle, fontFamily: "inherit" }}

              className="!ml-auto history-button !justify-self-end !text-sm !capitalize !px-2 !py-1.5 relative">
              Journey
              <h6 className="p-2 bg-gray-600 absolute -top-[50%] -right-2"
              >{questionHistory.filter((question) => question.isAnswerCorrect).length}</h6>
            </Button>
          </Tooltip> */}
          </div>
          {historyVisible && (
            <div
              //ref={questionListRef}
              className="w-full">
              <QuestionList
                isOpen={historyVisible}
                onClose={() => setHistory(false)}
                questionHistory={questionHistory}
                handleQuestionsHistory={handleQuestionsHistory}
                currentQuestionId={questionMeta?.userQuestionHistoryId || ""}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
