import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "../styles.css"
import { fetchQuestion, validateAnswer, runQuery, update, fetchSearchFields, fetchUpdatedToken } from "../utils/apis"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import QuestionComponent from "../assets/question"
import SolutionUploadComponent from "../assets/solution-upload"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserDetails, setUser } from "../redux/actions/user"
import ToolLevelForm from "./get-question-filters"
import { useNavigate, useSearchParams } from "react-router-dom"
import Header from "./question-header"
import { LEVELS, SUBJECTS, toastConfig } from "../utils/constants"
import { createArray } from "../utils/common-function"
import ResultComponent from "../assets/result"
import Modal from "../assets/modal"
import { LANGUAGES } from "../utils/constants"
import { Box, Button, MenuItem, Slider, Tooltip, Typography } from "@mui/material"
import useNetworkStatus from "../assets/network_detector"
import SplitPane, { Pane } from "split-pane-react"
import "split-pane-react/esm/themes/default.css"
import { SkipNext, SubjectOutlined } from "@mui/icons-material"
import Dropdown from "./ui/Dropdown"
import QuestionTimer from "../assets/timer"
import QuestionPageSkeleton from "./skeleton/QuestionPageSkeleton.jsx"
import { fetchUpdatedQuestionHistory } from "../redux/actions/questionhistory.js"
import { fetchUserMetrics } from "../redux/actions/usermetrics.js"
import RewardPopup from "../components/RewardPoint/RewardPopup.jsx"

const QuestionPage = () => {
  const levels = createArray(LEVELS)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userDetails, lastUpdated } = useSelector((state) => ({
    userDetails: state.user.userDetails,
    lastUpdated: state.questionHistory.lastUpdated,
  }))

  const [loader, setLoader] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const intialModal = {
    open: false,
    message: "",
    title: "Confirm Action",
    action: "",
  }

  const [confirmationModal, setConfirmationModal] = useState(intialModal)
  const [mode, setMode] = useState(LANGUAGES[0].key)
  const [subscribeComponent, setSubsscribeComponent] = useState(false)
  const [displayIntroForm, setDisaplyIntroForm] = useState(false)
  const [questionMeta, setQuestionMeta] = useState(null)

  const [preferences, setPreferences] = useState({
    tool: "",
    level: "",
    //techStack: "",
    topics: [],
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const initialResultSection = {
    submittedAnswer: "",
    answerResult: "",
    isError: false,
    result: null,
    submittedKey: "",
    resultLoader: false,
    runSol: false,
    countOfRows: 0,
  }

  const [resultSection, setResultSection] = useState(initialResultSection)
  const initialPendingStates = {
    tool: "",
    level: "",
    next: false,
    prev: false,
    exactQuestionId: "",
  }
  const [pendingUpdates, setPendingUpdates] = useState(initialPendingStates)

  const [searchFilterList, setSearchFilterList] = useState({})
  const [showSuccessPage, setShowSuccessPage] = useState(false)
  const [quesFetchError, setquesFetchError] = useState(false)
  const [isTimerPaused, setIsTimerPaused] = useState(false)

  const [showFreeArenaPopup, setShowFreeArenaPopup] = useState(false)
  const isAnswerSubmitted = false
  const { isOnline, isNetworkActive } = useNetworkStatus()
  const [showNetworkConn, setShowNetworkConn] = useState(false)

  const timer = useRef(0)
  const answerRewardPoints = useRef(null)
  const fetchingQuestion = useRef(false)
  const filtersUpdated = useRef(preferences.topics.length)
  const userPref = useMemo(() => [preferences.tool, preferences.level], [preferences])

  useEffect(() => {
    if (!isNetworkActive) {
      setShowNetworkConn(true)
    } else {
      setShowNetworkConn(false)
    }
  }, [isNetworkActive])

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("refreshing token")
      const refreshToken = localStorage.getItem("refreshToken")
      if (refreshToken) {
        fetchUpdatedToken().then((data) => {
          if (data.error) {
            toast.error(data.error, toastConfig)
          } else {
            localStorage.setItem("usertoken", data.token)
          }
        })
      }
    }, 300000)
    return () => clearInterval(interval)
  }, [])

  const getSearchFilters = async (tool, level) => {
    let searchFilters = await fetchSearchFields(new URLSearchParams({ tool, level }))
    if (searchFilters && searchFilters.data) {
      console.log("get filter called", searchFilters.data, tool, level)
      setSearchFilterList(searchFilters.data)
      setPreferences((prevState) => ({ ...prevState, topics: [] }))
    }
  }

  const confirmUpdate = async (confirm) => {
    const action = confirmationModal.action
    setConfirmationModal((prevState) => ({ ...prevState, open: false }))

    if (confirm) {
      switch (action) {
        case "toolUpdate":
          setPreferences((prevState) => ({ ...prevState, tool: pendingUpdates.tool }))
          break
        case "levelUpdate":
          setPreferences((prevState) => ({ ...prevState, level: pendingUpdates.level }))
          break
        case "next":
        case "previous":
          setPreferences((prevState) => ({ ...prevState }))
          break
        case "exactQuestionSwitch":
          setPreferences((prevState) => ({
            ...prevState,
            tool: pendingUpdates.tool ? pendingUpdates.tool : prevState.tool,
            level: pendingUpdates.level ? pendingUpdates.level : prevState.level,
          }))
          break
        // case "funcUpdate":
        //   setPreferences((prevState) => ({
        //     ...prevState,
        //     topics: pendingUpdates.topics,
        //   }))
        default:
          break
      }
      let pref = userDetails?.questionPreferences
      if (pref) {
        pref = {
          toolName: pendingUpdates.tool !== preferences.tool ? pendingUpdates.tool : preferences.tool,
          level: pendingUpdates.level !== preferences.level ? pendingUpdates.level : preferences.level,
          //functions: action === "toolUpdate" || "levelUpdate" ? [] : pendingUpdates.topics.length !== preferences.topics.length ? pendingUpdates.topics : preferences.topics,
        }
      }
      setUser((prevDetails) => ({
        ...prevDetails,
        questionPreferences: pref,
      }))
    } else {
      setPendingUpdates(initialPendingStates)
    }
  }

  useEffect(() => {
    dispatch(fetchUserDetails())
  }, [dispatch])

  useEffect(() => {
    if (userDetails && isInitialLoad && !quesFetchError) {
      const { toolName, level } = userDetails.questionPreferences || {}

      if (!userDetails.isVerified) {
        toast.error("Please verify your email to continue", toastConfig)
        navigate("/verify-email")
      }

      const hasShownPopup = localStorage.getItem("hasShownFreeArenaPopup")
      if (userDetails.loginCount === 1 && hasShownPopup !== "true") {
        setShowFreeArenaPopup(true)
        localStorage.setItem("hasShownFreeArenaPopup", "true")
      }

      if (toolName && level) {
        setPreferences((prev) => ({
          ...prev,
          tool: toolName,
          level: level,
          //topics: userDetails.questionPreferences?.functions || [],
        }))
        setDisaplyIntroForm(false)
      } else {
        setDisaplyIntroForm(true)
      }
    }
  }, [userDetails, isInitialLoad, quesFetchError])

  useEffect(() => {
    if (!fetchingQuestion.current && preferences.tool && preferences.level) {
      fetchingQuestion.current = true
      setLoader(true)
      fetchMetaData()
    }
  }, [preferences])

  useEffect(() => {
    const fetchFilters = async () => {
      if (preferences.tool && preferences.level) {
        try {
          getSearchFilters(preferences.tool, preferences.level)
        } catch (error) {
          console.error("Failed to fetch search filters:", error)
        }
      }
    }

    fetchFilters()
  }, [preferences.tool, preferences.level])

  const fetchMetaData = async () => {
    setLoader(true)

    try {
      await getQuestionMetaData()
      if (isInitialLoad) setIsInitialLoad(false)
      if (displayIntroForm) setDisaplyIntroForm(false)
    } catch (error) {
      console.error("Error fetching metadata:", error)
      setquesFetchError(true)
    } finally {
      fetchingQuestion.current = false
    }
  }

  const resetComponent = () => {
    setPendingUpdates(initialPendingStates)
    setQuestionMeta(null)
    setResultSection(initialResultSection)
    setShowSuccessPage(false)
    setIsTimerPaused(false)
    timer.current = 0
    answerRewardPoints.current = null
    filtersUpdated.current = preferences.topics.length
  }

  async function getQuestionMetaData() {
    if (!preferences.tool || !preferences.level) return
    console.log({ pendingUpdates, preferences, isInitialLoad, userDetails, quesFetchError })
    let questionId
    let isFuncUpdated = preferences.topics.length > filtersUpdated.current
    questionId = pendingUpdates.exactQuestionId || (pendingUpdates.level || pendingUpdates.tool || isFuncUpdated || (preferences.topics && pendingUpdates.next) ? "" : searchParams.get("questionId") || "")

    try {
      const queryParams = new URLSearchParams({
        questionId,
        toolName: preferences.tool,
        level: preferences.level,
        func: preferences.topics,
        isInitialLoad,
        toNext: pendingUpdates.next,
        toPrev: pendingUpdates.prev,
        isLevelUpdated: Boolean(pendingUpdates.level),
        isToolUpdated: Boolean(pendingUpdates.tool),
        isFuncUpdated,
      })

      resetComponent()

      let response = await fetchQuestion(queryParams)

      if (response.status === 200) {
        setQuestionMeta(response.data)
        navigate(`?questionId=${response.data.questionId}`, { replace: true })

        if (response.data?.answerSubmitted?.length && response.data.rewardPoints) {
          toast.info(`You have already submitted your solution & scored ${response.data.rewardPoints} reward points, however you can practice this problem as many times as you wish`, toastConfig)
        }
        if (preferences.tool === "Python") {
          setResultSection((prevState) => ({ ...prevState, submittedAnswer: response.data.solutionTemplate }))
        }
        if (response.data.rewardPoints) {
          setIsTimerPaused(true)
        }
        timer.current = response.data.totalTimeSpent || 0
      } else {
        toast.error(response.message || "Some error has occurred", toastConfig)

        setIsTimerPaused(true)
        if (response.message === "No question found") {
          setLoader(false)
        }
        if (response.message === "Free tier complete") {
          setSubsscribeComponent(true)
          setLoader(false)
        }
        setquesFetchError(true)
      }
    } catch (error) {
      console.error(error)
      setquesFetchError(true)
      toast.error("Some error has occurred", toastConfig)
    } finally {
      setLoader(false)
      if (lastUpdated) {
        dispatch(fetchUpdatedQuestionHistory(lastUpdated))
      }
      dispatch(fetchUserMetrics())
    }
  }

  const handleSolution = (value) => {
    setResultSection((prevState) => ({ ...prevState, submittedAnswer: value }))
  }

  const getValidateObj = (key, ans) => {
    const formData = new FormData()
    const answer = ans || resultSection.submittedAnswer
    let { tool } = preferences
    const { questionId, userQuestionHistoryId, ExcelFunctions, SQLFunctions, PythonFunctions } = questionMeta
    const functionsMap = {
      Excel: ExcelFunctions,
      SQL: SQLFunctions,
      Python: PythonFunctions,
    }

    formData.append(tool === "Excel" ? "file" : "submittedAnswer", answer)
    formData.append("questionId", questionId)
    formData.append("techStack", tool)
    formData.append("language", LANGUAGES.find((l) => l.key === mode).value)
    formData.append("userQuestionHistoryId", userQuestionHistoryId)
    formData.append("submittedKey", key)

    if (functionsMap[tool]) {
      formData.append(`${tool}Functions`, functionsMap[tool])
    }
    if (key === "submit") {
      formData.append("timeSpent", timer.current)
    }

    return formData
  }

  const updateResultSection = (response, key) => {
    let isAnswerCorrect = false
    if (response.status === 200) {
      if (response.data.isAnswerCorrect) {
        setResultSection((prevState) => ({
          ...prevState,
          answerResult: true,
          result: response.data.submittedResult,
          resultLoader: false,
          countOfRows: response.data.count,
        }))
        isAnswerCorrect = true
        localStorage.removeItem(`${questionMeta.userQuestionHistoryId}-solution`)
      } else {
        setResultSection((prevState) => ({ ...prevState, isError: false, result: key === "submit" ? response.message : response.data.submittedResult, resultLoader: false, countOfRows: response.data.count }))
      }
    } else {
      setResultSection((prevState) => ({ ...prevState, isError: true, result: response.message || "Some error occurred", resultLoader: false }))
    }

    if (isAnswerCorrect) answerRewardPoints.current = response.rewardPoints

    if (key === "run" && !questionMeta.rewardPoints > 0) {
      setIsTimerPaused(false)
    }
    if (key === "submit") {
      setShowSuccessPage(true)
      setIsTimerPaused(isAnswerCorrect)
      if (isAnswerCorrect) {
        isAnswerSubmitted = true
      }
    }
  }

  const onSubmit = async (key, answer) => {
    try {
      if (!answer) {
        toast.error("Please add the solution inside function", toastConfig)
        setIsTimerPaused(false)
        return
      } else {
        setIsTimerPaused(true)
        setResultSection((prevState) => ({ ...prevState, submittedAnswer: answer, resultLoader: true, runSol: key === "run" ? true : prevState.runSol, answerResult: false }))
        let formData = getValidateObj(key, answer)
        let response = key === "submit" ? await validateAnswer(formData) : await runQuery(formData)

        updateResultSection(response, key)
      }
    } catch (error) {
      setResultSection((prevState) => ({ ...prevState, isError: true, result: error.message || "Some error occurred" }))
    }
  }

  const onChangeTechStack = async (t) => {
    const currQuestionId = searchParams.get("questionId")
    const updatinglevel = questionMeta.Level[t]

    setPendingUpdates((prevState) => ({ ...prevState, exactQuestionId: currQuestionId, level: preferences.level === updatinglevel ? preferences.level : updatinglevel, tool: t }))
    setPreferences((prevState) => ({ ...prevState, level: preferences.level === updatinglevel ? preferences.level : updatinglevel, tool: t }))
  }

  const onSearchFilter = useCallback((v) => {
    setPreferences((prevState) => ({
      ...prevState,
      topics: [...new Set([...(prevState.topics || []), ...(v?.functions || [])])],
    }))

    //onChange("funcUpdate", [...new Set(v?.functions)])
  })

  const onChange = async (action, ...args) => {
    const [targetValue, exactTool, exactLevel] = args
    const runStatus = resultSection.runSol
    const messages = {
      toolUpdate: runStatus ? `You've already run the solution. Are you sure you want to switch to the ${targetValue} tool and reset your progress on this question?` : `Switching to the ${targetValue} tool will skip the current question. Are you sure?`,
      levelUpdate: runStatus ? `You’ve tested your solution at this level. Changing to ${targetValue} will start fresh. Confirm change?` : `Switching to level ${targetValue} will skip the current question. Continue?`,
      next: runStatus ? `Try submitting your solution before moving to the next question. Ready to proceed?` : `Advance to the next question? Unsaved work will be lost.`,
      previous: runStatus ? `It seems you’ve made progress. Consider submitting before going back. Still want to switch?` : `Are you sure you want to revisit the previous question?`,
      exactQuestionSwitch: runStatus ? `You’re about to switch to a specific question, which will reset your current progress. Continue?` : `Switching questions will discard your progress here. Ready to switch?`,
      //funcUpdate: runStatus ? `You've already made changes. Applying a new filter might reset these changes. Proceed?` : `Applying a filter will reset your current changes. Continue?`,
    }

    const currPendingUpdates = {
      toolUpdate: { tool: targetValue },
      levelUpdate: { level: targetValue },
      next: { next: true },
      previous: { prev: true },
      exactQuestionSwitch: {
        exactQuestionId: targetValue,
        tool: preferences.tool !== exactTool ? exactTool : pendingUpdates.tool,
        level: preferences.level !== exactLevel ? exactLevel : pendingUpdates.level,
      },
      //funcUpdate: { topics: targetValue },
    }

    setPendingUpdates((prevState) => ({ ...prevState, ...currPendingUpdates[action] }))
    setConfirmationModal((prevState) => ({
      ...prevState,
      message: messages[action],
      open: true,
      action: action,
    }))
  }

  const onModalClose = async () => {
    setConfirmationModal({ ...intialModal })
    setPendingUpdates({ ...initialPendingStates })
  }

  const [sizes, setSizes] = useState([400, "40%"])

  const inlineStyle = {
    display: "flex",
    height: "calc(100vh - 89px)",
    marginTop: "90px",
    backgroundColor: "rgba(35, 38, 39, 1)",
  }
  const inlineStyleLoading = {
    overflow: "auto",
    height: "calc(100vh - 50px)",
    width: "100%",
    marginTop: "50px",
    backgroundColor: "rgba(35, 38, 39, 1)",
    position: "relative",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }

  console.log("Rendering...")

  const onTimeUpdate = useCallback((updatedTime) => {
    timer.current = updatedTime
  })
  console.log(questionMeta, "Meta Bagha")

  return (
    <div>
      <Header
        primaryTool={preferences.tool}
        selectedLevel={preferences.level}
        onChangeSubject={(subject) => onChange("toolUpdate", subject)}
        onChangeLevel={(level) => onChange("levelUpdate", level)}
        levels={levels}
        onSubmit={onSubmit}
        searchFilterList={searchFilterList}
        onSearchFilter={onSearchFilter}
        selectedTopic={preferences.topics}
        questionMeta={questionMeta}
        handleQuesSwitch={(next, previous) => (next === true ? onChange("next") : onChange("previous"))}
        handleSwitchtoExactQues={(...args) => onChange("exactQuestionSwitch", ...args)}
        stopTimer={() => setIsTimerPaused(true)}
        startTimer={() => setIsTimerPaused(false)}
      />
      {loader ? (
        <div style={inlineStyleLoading}>
          <QuestionPageSkeleton />
        </div>
      ) : displayIntroForm ? (
        <>
          <ToolLevelForm
            onSubmit={(e) => {
              setPreferences((prevState) => ({ ...prevState, tool: e.tool, level: e.level }))
              setUser((prevDetails) => ({
                ...prevDetails,
                questionPreferences: { tool: e.tool, level: e.level },
              }))
            }}
          />
        </>
      ) : (
        <>
          {!questionMeta ? (
            <div style={inlineStyleLoading}>
              <div className="flex flex-col justify-start items-center">
                {quesFetchError && (
                  <>
                    <div className="mx-auto my-4">
                      <h2 className="font-bold text-3xl text-gray-200">Unable to fetch current question</h2>
                      <div className="gradient" />
                    </div>
                    <div className="shiny-border max-w-md">
                      <div className="myCard p-6 !bg-[#1f2021]">
                        <Button
                          variant="outlined"
                          onClick={() => onChange("next")}
                          className={`!flex-1  !shadow-none  !text-yellow-600 !border-yellow-600 !my-4 !ml-auto`}
                          size="small"
                          endIcon={<SkipNext />}>
                          Switch to Next Question
                        </Button>
                        <div id="subject-dropdown">
                          <Dropdown
                            title={preferences.tool || "Tools"}
                            tooltip={`Primary tool selected for the situation is ${preferences.tool}`}
                            icon={<SubjectOutlined />}
                            children={SUBJECTS.map((subject) => (
                              <MenuItem
                                key={subject}
                                onClick={() => {
                                  subject !== preferences.tool && onChange("toolUpdate", subject)
                                }}
                                className="!text-gray-200 !font-light !text-xs">
                                {subject}
                              </MenuItem>
                            ))}
                          />
                        </div>
                        <Box className="w-full">
                          <Typography
                            id="level-slider"
                            gutterBottom
                            className="!text-gray-200 !my-4">
                            Level (1-{LEVELS})
                          </Typography>
                          <Slider
                            value={preferences.level}
                            onChange={(event) => onChange("levelUpdate", event.target.value)}
                            aria-labelledby="level-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={LEVELS}
                            className="!text-gray-200"
                          />
                          <Button
                            variant="outlined"
                            size="small"
                            disabled
                            className="hover:!bg-transparent !font-medium !text-xs !px-2 !py-1.5 !capitalize !my-3 !rounded-none !border-0  !border-b !text-gray-400 !border-gray-400 hover:!text-gray-200">
                            Current level: {preferences.level}
                          </Button>
                        </Box>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : questionMeta ? (
            <div>
              <div>
                <SplitPane
                  split="vertical"
                  sizes={sizes}
                  onChange={setSizes}
                  style={inlineStyle}>
                  <Pane
                    minSize={500}
                    maxSize="50%">
                    <div className="question-data-wrapper">
                      <QuestionComponent
                        questionMeta={questionMeta}
                        techStack={preferences.tool}
                        onSubmit={onSubmit}
                        isQuestionFetched={questionMeta ? true : false}
                        onChangeTechStack={onChangeTechStack}
                        techStacks={questionMeta?.techStacks}
                        primaryTool={preferences.tool}
                        userDetails={userDetails}
                      />
                    </div>
                  </Pane>
                  <div className="question-data-wrapper">
                    <SolutionUploadComponent
                      tool={preferences.tool}
                      handleFileUpload={handleSolution}
                      handleTextInput={handleSolution}
                      answer={resultSection.submittedAnswer}
                      handleModeChange={(value) => {
                        setMode(value)
                      }}
                      LANGUAGES={LANGUAGES}
                      mode={mode}
                      onSubmit={onSubmit}
                      onChangeTechStack={onChangeTechStack}
                      techStacks={questionMeta?.techStacks}
                      selectedTechStack={preferences.tool}
                      isQuestionFetched={questionMeta ? true : false}
                      primaryTool={preferences.tool}
                      questionMeta={questionMeta}
                      timer={timer.current}
                      stopTimer={() => setIsTimerPaused(true)}
                      startTimer={() => setIsTimerPaused(false)}
                      resultLoader={resultSection.resultLoader}
                      handleCurrentAnswer={() => setResultSection((prevState) => ({ ...prevState, submittedAnswer: preferences.tool === "SQL" ? "" : questionMeta.solutionTemplate }))}>
                      <QuestionTimer
                        userQuestionHistoryId={questionMeta.userQuestionHistoryId}
                        elapsedTime={timer.current}
                        isTimerPaused={isTimerPaused}
                        onTimeUpdate={onTimeUpdate}
                        onQuestionSwitch={loader && !isInitialLoad}
                        isAnswerSubmitted={isAnswerSubmitted}
                      />
                    </SolutionUploadComponent>

                    <ResultComponent
                      result={resultSection.result}
                      isAnswerCorrect={resultSection.answerResult}
                      resultLoader={resultSection.resultLoader}
                      submittedKey={resultSection.submittedKey}
                      countOfRows={resultSection.countOfRows}
                    />
                  </div>
                </SplitPane>
              </div>
            </div>
          ) : null}
        </>
      )}
      {confirmationModal.open && confirmationModal.message && (
        <Modal
          isOpen={confirmationModal.open}
          onClose={onModalClose}
          title={confirmationModal.title}>
          <p className="text-gray-400 text-sm mt-2">{confirmationModal.message}</p>
          <div className="flex gap-2 mt-4">
            <Button
              variant="contained"
              size="small"
              className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
              onClick={() => confirmUpdate(true)}>
              Yes
            </Button>
            <Button
              variant="outlined"
              size="small"
              className="!shadow-none !text-gray-200 !border !border-gray-600"
              onClick={() => confirmUpdate(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}
      {showFreeArenaPopup && (
        <Modal
          isOpen={showFreeArenaPopup}
          onClose={() => setShowFreeArenaPopup(false)}
          title="Welcome to Marma AI!">
          <p className="text-gray-400 text-sm mt-2">Hi {userDetails.name}, We are pleased to offer you free access to Marma Arena. You can explore it in the Arenas section. Enjoy your learning journey with Marma AI.</p>
        </Modal>
      )}
      {showNetworkConn && (
        <Modal
          isOpen={showNetworkConn}
          onClose={() => setShowNetworkConn(false)}
          title="Check your Internet connection">
          <p className="text-gray-400 text-sm mt-2">Reconnect to submit/run your solutions</p>
        </Modal>
      )}
      {subscribeComponent && (
        <Modal
          isOpen={subscribeComponent}
          onClose={() => setSubsscribeComponent(!subscribeComponent)}
          title="Free trial Complete!">
          <p className="text-gray-200 text-md mt-2 font-semibold">Your Data Journey Doesn't Stop Here - Unlock More Challenges and Mastery with Premium!</p>
          <div className="flex gap-2 mt-4">
            <Button
              variant="contained"
              size="small"
              className="!shadow-none !bg-green-600 !text-gray-200 !border-0"
              onClick={() => navigate("/pricing")}>
              Try Premium
            </Button>
            <Button
              variant="outlined"
              size="small"
              className="!shadow-none !text-gray-200 !border !border-gray-600 !ms-4"
              onClick={() => setSubsscribeComponent(!subscribeComponent)}>
              Try Later
            </Button>
          </div>
        </Modal>
      )}
      {showSuccessPage && (
        <Modal
          isOpen={showSuccessPage}
          onClose={() => setShowSuccessPage(false)}
          title={null}>
          <RewardPopup
            isCorrect={resultSection.answerResult}
            getQuestionMetaData={(e) => {
              setPendingUpdates((prevState) => ({ ...prevState, next: true }))
              setPreferences({ ...preferences })
            }}
            setShowSuccessPage={setShowSuccessPage}
            rewardPoints={answerRewardPoints.current}></RewardPopup>
        </Modal>
      )}
    </div>
  )
}

export default QuestionPage
