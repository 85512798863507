import React from 'react'
import './cross.css'
const Cross = () => {
    return (
        <svg className="cross__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="cross__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
            <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20" />
        </svg>
    )
}

export default Cross