import React, { useEffect, useState } from 'react'
import { fetchLeaderBoardData } from '../../../utils/apis'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { CircularProgress } from '@material-ui/core';
import RewardCoinWithoutAnimation from '../../RewardPoint/RewardCoinWithoutAnimation';

const Leaderboard = ({ arenaId }) => {
    const [filterInput, setFilterInput] = useState({ filterType: "Arena", arenaId: arenaId, roadmapName: "" })

    const [leaderBoardData, setLeaderBoardData] = useState()
    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchArenaLeaderBoard = async () => {
        setIsDataLoading(true)
        const response = await fetchLeaderBoardData(filterInput)

        if (response.status === 200) {
            let data = response.data.sort((a, b) => b.totalRewardPoints - a.totalRewardPoints)
            data = data.map((item, index) => ({ ...item, rank: index + 1 }))

            setLeaderBoardData(data)
            //   const user = data.filter((item) => item.isCurrentUser === true)

            setIsDataLoading(false)
        } else {
            console.log("Data nahi ala re leaderboard cha")
            setIsDataLoading(false)
        }
    }

    useEffect(() => {
        fetchArenaLeaderBoard();
    }, [])

    return (
        <div className='min-w-[750px] flex flex-col'>
            <div className="mx-auto my-3">
                <h2 className="font-bold text-3xl text-gray-200 text-center">
                    Leaderboard
                </h2>
                <div className="gradient" />
            </div>
            {
                isDataLoading ? <>
                    <CircularProgress className='mx-auto mt-3 text-gray-400' color="inherit" />
                </> :
                    <>
                        {
                            leaderBoardData?.length === 0 ?
                                <h2 className="font-normal text-xl text-gray-400 text-center">
                                    No Data Available
                                </h2>
                                :
                                <TableContainer className='!w-full'>
                                    <Table size='small' aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='!text-gray-400'>Sr. No</TableCell>
                                                <TableCell className='!text-gray-400'>Name</TableCell>
                                                <TableCell className='!text-gray-400'>Rank</TableCell>
                                                <TableCell className='!text-gray-400'>Questions Solved</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {leaderBoardData?.map((row, index) => (
                                                <TableRow
                                                    key={row?.uniqueId}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                >
                                                    <TableCell className='!text-gray-200' component="th" scope="row">
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell className='!text-gray-200'>{row?.fullName}</TableCell>
                                                    <TableCell className='!text-gray-200'>
                                                        {
                                                            (row?.rank > 0 && row?.rank < 4) ? (
                                                                <div className="bg-[#343839] text-[#D0AA45] text-xs font-bold px-2.5 py-0.5 rounded flex gap-1 justify-center items-center cursor-default w-1/2">
                                                                    <RewardCoinWithoutAnimation /> {row?.rank}
                                                                </div>
                                                            ) :
                                                                row?.rank
                                                        }

                                                    </TableCell>
                                                    <TableCell className='!text-gray-200'>{row?.totalQuestionsSolved}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                        }
                    </>
            }
        </div>
    )
}

export default Leaderboard