const initialState = {
  questionHistory: [],
  lastUpdated: null,
}

const questionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUESTION_HISTORY":
      const { questionHistory, lastUpdated } = action.payload

      const updatedQuestionHistory = questionHistory.reduce(
        (acc, newQuestion) => {
          const existingQuestionIndex = acc.findIndex((q) => q.userQuestionHistoryId === newQuestion.userQuestionHistoryId)

          if (existingQuestionIndex > -1) {
            // Replace the existing question with the updated one in its current position
            acc[existingQuestionIndex] = newQuestion
          } else {
            if (state.lastUpdated === null) {
              // For initial load, append to the end (push)
              acc.push(newQuestion)
            } else {
              // For subsequent requests, prepend new questions to the top (unshift)
              acc.unshift(newQuestion)
            }
          }

          return acc
        },
        [...state.questionHistory]
      ) // Start with the existing state

      return {
        ...state,
        questionHistory: updatedQuestionHistory,
        lastUpdated, // Update the lastFetched timestamp
      }
    default:
      return state
  }
}

export default questionHistoryReducer
