import React, { useState } from 'react'
import "../../styles.css"
import BoxPageLoader from '../fancy_loader/BoxPageLoader'
import SplitPane from 'split-pane-react/esm/SplitPane'
import { Pane } from 'split-pane-react'


const QuestionPageSkeleton = () => {
    const [sizes, setSizes] = useState([400, "40%"])
    const inlineStyle = {
        display: "flex",
        height: "calc(100vh - 89px)",
        backgroundColor: "rgba(35, 38, 39, 1)",
    }

    return (
        <SplitPane
            split="vertical"
            sizes={sizes}
            onChange={setSizes}
            style={inlineStyle}
            className='mt-10'
        >
            <Pane
                minSize={500}
                maxSize="50%"
                className='h-full'
            >
                <div className="question-data-wrapper flex flex-col justify-center items-center">
                    <BoxPageLoader />
                    <div class="my-10 text-xl font-bold text-center">
                        <span class="animate-pulse bg-gradient-to-r from-gray-200 via-gray-400 to-gray-200 bg-clip-text text-transparent">
                            {/* Please Wait..! We're building up the perfect challenge for you... */}
                        </span>
                    </div>
                </div>
            </Pane>
            <div className="question-data-wrapper flex flex-col justify-center items-center">
                <BoxPageLoader />
                <div class="my-10 text-xl font-bold text-center">
                    <span class="animate-pulse bg-gradient-to-r from-gray-200 via-gray-400 to-gray-200 bg-clip-text text-transparent">
                        Please Wait..! We're building up the perfect challenge for you...
                    </span>
                </div>
            </div>
        </SplitPane>
    )
}

export default QuestionPageSkeleton