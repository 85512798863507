import React from "react";
import "./wallet.css";
const Wallet = () => {
    return (
            <div className="wallet absolute bottom-12 left-1/2 transform -translate-x-1/2" id="wallet">
                <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x": "calc(-100px + 24px)", "--coin-to-y": "calc(-105px + 24px)", " --coin-delay": "0.3s" }}
                ></div>
                <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x": "calc(-70px + 24px", "--coin-to-y": "-90px", "--coin-delay": "0.1s" }}
                ></div>
                {/* <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x": "calc(-30px + 24p)", "--coin-to-y": "-125", "; --coin-delay": "0s" }}
                ></div> */}
                <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x": "calc(10px + 24px)", "--coin-to-y": "-130px", "--coin-delay": "0.2s" }}
                ></div>
                <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x": "calc(30px + 24px)", "--coin-to-y": "-100px", "--coin-delay": "0.1s" }}
                ></div>
                <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x": "calc(70px + 24px", "--coin-to-y": "-95px", "--coin-delay": "0.4s" }}
                ></div>
                <div
                    className="anicoin coin--animated"
                    style={{ "--coin-to-x:": "calc(100px + 24px)", "--coin-to-y": "-100px", "--coin-delay": "0.2s" }}
                ></div>
            </div>
    );
};

export default Wallet;
