import html2canvas from 'html2canvas';

export const takeScreenshot = (refDiv) => {

      const element = refDiv;

      html2canvas(element).then((canvas) => {
        const screenshotURL = canvas.toDataURL('image/png');
  
        // Create a link to download the screenshot
        const link = document.createElement('a');
        link.href = screenshotURL;
        link.download = 'Performance.png';
        link.click();
      });
    };