import React from 'react';
import './css_files/modal.css'; // Import your modal CSS styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Typography, Modal as MUIModal } from '@mui/material';

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
  };
  return (
    // <div className="modal-overlay">
    //   <div className="modal text-black">
    //     <div className="modal-header">
    //       <h2>{title}</h2>
    //       <button onClick={onClose} className="modal-close-button"><FontAwesomeIcon icon = {faTimesCircle}/></button>
    //     </div>
    //     <div className="modal-body">
    //       {children}
    //     </div>
    //   </div>
    // </div>

    <MUIModal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className='bg-transparent backdrop-blur-sm'
    >
      <Box sx={style} className="border-l border-b border-gray-600 bg-gray-900 bg-opacity-70 rounded-lg">
        <div className="flex justify-center flex-col p-4 items-center">
          <Typography style={{ fontFamily: "inherit" }} id="modal-modal-title" className='text-gray-200 !m-0 !p-0' variant="h6" component="h2">
            {title}
          </Typography>
          {children}
        </div>
      </Box>


    </MUIModal>
  );
};

export default Modal;
