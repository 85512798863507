import React, { useState, useEffect } from "react"
import { validateCouponCode, getAvailableCoupons } from "../utils/apis"
import RazorpayPaymentComponent from "./razorpaygateway"
import { Button, Modal } from "@mui/material"
import CommonHeader from "../assets/common-header"
import { toastConfig } from "../utils/constants"
import { toast } from "react-toastify"

const Checkout = ({ tier, arena, user }) => {
  // Determine the type of item (arena or tier)
  const item = tier ? { ...tier, itemType: "tier" } : { ...arena, itemType: "arena" }

  const [selectedPlan, setSelectedPlan] = useState(item.itemType === "arena" ? "Monthly" : item.type)
  const [originalPrice, setOriginalPrice] = useState(item.itemType === "arena" ? (selectedPlan === "Monthly" ? item.price?.monthly?.amount : item.price?.yearly?.amount) : item.price)
  const [totalAmount, setTotalAmount] = useState(originalPrice)
  const [couponCode, setCouponCode] = useState("")
  const [discount, setDiscount] = useState(0)
  const [error, setError] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [isCouponApplied, setIsCouponApplied] = useState(false)
  const [availableCoupons, setAvailableCoupons] = useState([])

  useEffect(() => {
    if (item.itemType === "arena") {
      const price = selectedPlan === "Monthly" ? item.price?.monthly?.amount : item.price?.yearly?.amount
      if (isCouponApplied) {
        setOriginalPrice(price)
        setTotalAmount(price)
      } else {
        setOriginalPrice(price)
        setTotalAmount(price)
        removeCoupon()
      }
    }
  }, [isCouponApplied])

  useEffect(() => {
    const fetchCoupons = async () => {
      const response = await getAvailableCoupons()
      if (response.status === 200) {
        setAvailableCoupons(response.data)
      } else {
        toast.error("No coupons Available", toastConfig)
      }
    }
    fetchCoupons()
  }, [])

  const removeCoupon = () => {
    setIsCouponApplied(false)
    setDiscount(0)
    setCouponCode("")
    setError("")
    setTotalAmount(originalPrice) // Reset total amount to original price
  }

  const applyCoupon = async (e) => {
    e.preventDefault()
    if (!couponCode) {
      setError("Please enter a coupon code")
      return
    }
    console.log("Applying coupon:", couponCode)
    const couponObj = {
      couponCode: couponCode,
      productId: item._id,
      userId: user.userId,
    }
    const response = await validateCouponCode(couponObj)
    if (response.status === 200) {
      setIsCouponApplied(true)
      let discountAmount
      if (response.type === "percentage") {
        discountAmount = originalPrice * (response.discountAmount / 100)
      } else if (response.type === "amount") {
        discountAmount = response.discountAmount
      } else {
        setError("Invalid coupon type")
        return
      }
      setDiscount(discountAmount)
      setTotalAmount(originalPrice - discountAmount)
      setError("")
    } else {
      setError(response.error)
    }
  }

  const proceedToPayment = () => {
    setIsProcessing(true)
  }

  const renderPaymentComponent = () => {
    if (isProcessing) {
      const updatedItem = {
        ...item,
        couponCode,
        planType: selectedPlan.toLowerCase(),
        currency: item.itemType === "arena" ? item.price[selectedPlan.toLowerCase()].currency : item.currency,
      }
      return (
        <RazorpayPaymentComponent
          tier={updatedItem}
          user={user}
          onDismiss={() => setIsProcessing(false)}
        />
      )
    }
  }

  return (
    <>
      {isProcessing && renderPaymentComponent()}
      <CommonHeader />
      <div className="bg-[#232627] h-[calc(100vh-50px)]">
        <div className="shiny-border max-w-md mx-auto p-6 rounded-md shadow-md text-gray-200 mt-5">
          <div className="myCard p-6 !bg-[#1f2021]">
            <h2 className="text-2xl font-semibold mb-4">Checkout</h2>
            <div className="mb-4">
              <div className="flex justify-between">
                <span>{item.itemType === "tier" ? item.title : item.name}</span>
                <span className="font-semibold">
                  {item.currency === "USD" ? "$ " : "Rs "}
                  {totalAmount?.toFixed(2)}
                </span>
              </div>
              <div className="mt-2 text-sm text-gray-400">
                Original Price {item.currency === "USD" ? "$" : "Rs"}: {originalPrice.toFixed(2)}
              </div>
              {discount > 0 && (
                <div className="mt-2 text-sm text-green-500">
                  Discount: {item.currency === "USD" ? "$" : "Rs"}
                  {discount.toFixed(2)}
                </div>
              )}
            </div>
            <div className="mb-4">
              {availableCoupons.length > 0 && (
                <div>
                  <label className="block mb-2 text-gray-400">Select Coupon:</label>
                  <select
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    className="input mb-3">
                    <option
                      value=""
                      disabled>
                      Select Coupon
                    </option>
                    {availableCoupons.map((coupon) => (
                      <option
                        value={coupon.code}
                        key={coupon.code}>
                        {coupon.code}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {isCouponApplied ? (
                <button
                  className="mt-2 bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                  onClick={removeCoupon}>
                  Remove Coupon
                </button>
              ) : (
                <Button
                  variant="contained"
                  className="mt-2 bg-blue-500 text-white hover:bg-blue-600 !my-2 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize"
                  onClick={applyCoupon}>
                  Apply Coupon
                </Button>
              )}
              {isCouponApplied && discount > 0 && <div className="text-sm text-green-500 mt-2">Coupon applied successfully</div>}
              {error && <div className="text-red-500 mt-2">{error}</div>}
            </div>
            <div>
              <label className="block mb-2 text-gray-400">Select Plan:</label>
              <select
                value={selectedPlan}
                onChange={(e) => setSelectedPlan(e.target.value)}
                disabled={item.itemType === "tier"}
                className="input mb-3">
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
            <Button
              variant="outlined"
              className="!font-medium !text-green-400 !border-green-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize"
              onClick={proceedToPayment}
              disabled={isProcessing}>
              {isProcessing ? "Processing..." : "Proceed to Payment"}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Checkout
