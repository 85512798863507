import { userperformanceData } from "../../utils/apis"

export const setUserMetrics = (user_metrics) => {
  return {
    type: "SET_USER_METRICS",
    payload: user_metrics,
  }
}

export const fetchUserMetrics = () => {
  return async (dispatch) => {
    try {
      let response = await userperformanceData()

      if (response.status === 200) {
        const currentUsermetrics = response.data
        dispatch(setUserMetrics(currentUsermetrics))
      }
    } catch (error) {
      console.error("Error fetching question history", error)
    }
  }
}
