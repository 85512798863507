import React from "react"
import RewardCoin from "./RewardCoin"
import { Button } from "@mui/material"
import Cross from "./Cross.jsx"

const RewardPopup = ({ isCorrect = true, getQuestionMetaData, setShowSuccessPage, rewardPoints = 10 }) => {
  const compliments = ["Great Job!", "Nice work!", "You are the Best!", "Amazing Job!", "Excellent effort!", "Impressive skills!", "Brilliant execution!", "You're a star!", "You nailed it!"]

  const wrongAnswers = ["Oops! Try again.", "Not quite, give it another shot!", "Not this time, give it another go!", "Oops! You'll get it next time!", "Oops! Keep trying, you’re doing great!"]

  function getRandomCompliment() {
    if (isCorrect) {
      return compliments[Math.floor(Math.random() * compliments.length)]
    }
    return wrongAnswers[Math.floor(Math.random() * wrongAnswers.length)]
  }

  const renderButtons = () => {
    if (isCorrect) {
      return (
        <>
          <Button
            variant="contained"
            size="small"
            className="!shadow-none !bg-green-600 !text-gray-200 !border-0"
            onClick={() => getQuestionMetaData(true)}>
            Next Question
          </Button>
          {/* <Button
                    variant="outlined"
                    size="small"
                    className="!shadow-none !text-gray-200 !border !border-gray-600 !mx-2"
                    onClick={() => setShowSuccessPage(false)}>
                    
                </Button> */}
        </>
      )
    } else {
      return (
        <>
          <Button
            variant="contained"
            size="small"
            className="!shadow-none !bg-green-600 !text-gray-200 !border-0"
            onClick={() => setShowSuccessPage(false)}>
            Try Again
          </Button>
          <Button
            variant="outlined"
            size="small"
            className="!shadow-none !text-gray-200 !border !border-gray-600 !ms-4"
            onClick={() => getQuestionMetaData(true)}>
            Skip Question
          </Button>
        </>
      )
    }
  }
  return (
    <div className="relative">
      {isCorrect ? <RewardCoin /> : <Cross />}
      <div className="p-4 md:p-5 text-center">
        <h3 className={`text-xl ${isCorrect ? `text-green-500` : `text-red-500`} mb-2`}>{isCorrect ? "Correct Submission" : "Wrong Submission"}</h3>
        <h3 className="text-2xl font-semibold text-yellow-500 mb-4">{getRandomCompliment()}</h3>
        {rewardPoints && isCorrect && <h3 className="mb-5 text-lg font-normal text-gray-200">{`You've won ${rewardPoints} reward points`}</h3>}

        {renderButtons()}
      </div>
    </div>
  )
}

export default React.memo(RewardPopup)
