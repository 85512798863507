import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import CommonHeader from "../assets/common-header"
import Loader from "../assets/loader"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

function FailedPage() {
  const navigate = useNavigate()

  return (
    <div className="min-h-screen bg-[#232627] flex flex-col items-center">
      <CommonHeader />
      <div className="flex-grow flex flex-col justify-center items-center p-4">
        <div className="shiny-border">
          <div className="myCard p-6  !bg-[#1f2021]">
            <h1 className="text-xl font-semibold text-red-500 mb-4">Payment Failed :(</h1>
            <p className="text-gray-200 mb-6">Your payment has failed. Please try again.</p>
            <Link
              to="/pricing"
              className="!font-medium !text-green-400  !border-green-600 !rounded-lg !text-sm !px-2 !py-1.5 !capitalize transition duration-300">
              Try Again
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FailedPage
