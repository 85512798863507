import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { marmaLogo } from "../../assets/image_files/index.js"
import { CodeRounded, Menu, ScreenshotMonitorOutlined, ShowChartRounded } from "@mui/icons-material"
import { Drawer, Tooltip as MaterialTooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@mui/material"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDatabase, faFileExcel } from "@fortawesome/free-solid-svg-icons"
import DashboardStackedBarChart from "../../assets/progress-barchart.js"
import DashboardPieChart from "../../assets/progress-piechart.js"
import SWAccuracyChart from "../../assets/strength-chart.js"
import Fchart from "../../assets/frequency-chart.js"
import { takeScreenshot } from "../../utils/screenShot.js"
import RewardCoinWithoutAnimation from "../RewardPoint/RewardCoinWithoutAnimation.jsx"
import HeatMapChart from "../dashboard/HeatMapChart.jsx"

const PublicDashboard = () => {
  // This data would be fetched from a backend service
  const [interval, setInterval] = useState("Daily")
  const [metric, setMetric] = useState("questionsSolved")
  const [data, setCurruserData] = useState()
  const [rewardPoints, setRewardPoints] = useState("")
  const [isSubmissionChartVisible, setIsSubmissionChartVisible] = useState(false)
  const [barChartData, setBarChartData] = useState([])

  const handleUserData = (data) => setCurruserData(data)
  const [active, setActive] = useState("fchart")

  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }

  const useStyles = makeStyles(() => ({
    button: {
      background: "none",
      border: "none",
      color: "inherit",
      textAlign: "center",
      padding: "0.2rem",
      cursor: "pointer",
      width: "100%",

      borderRadius: "8px",
    },
    hidden: {
      display: "none",
    },
  }))
  const classes = useStyles()

  useEffect(()=>{
    console.log(barChartData, "submission data")

  },[barChartData])

  return (
    <div className="public-dashboard-container">
      <div className="dashboard-page">
        <div className="block md:hidden sm:block">
          <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            anchor="right"
            classes={{ paper: classes.paper }}>
            <div className="px-4 py-2 bg-[#171721] text-gray-200 h-full">
              <ul className="metrics-container">
                {data &&
                  data.map((entry, index) => (
                    <li
                      key={index}
                      className="">
                      <div className="flex-row-container gap-[0.25rem]">
                        {entry.totalRewardPoints && (
                          <MaterialTooltip
                            placement="bottom"
                            arrow="true"
                            title="Total Reward Points">
                            <h6 className="header gap-1">
                              {" "}
                              <RewardCoinWithoutAnimation />
                              {entry.totalRewardPoints}
                            </h6>
                          </MaterialTooltip>
                        )}
                        {entry.totalQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total Questions Solved"
                            arrow="true">
                            <div className="header gap-1">
                              <ShowChartRounded />
                              <span>{entry.totalQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                        {entry.totalPythonQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total Python Questions Solved"
                            arrow="true">
                            <div className="header gap-1">
                              <CodeRounded />
                              <span>{entry.totalPythonQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                        {entry.totalSQLQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total SQL Questions Solved"
                            arrow="true">
                            <div className="header gap-1 flex-row-container">
                              <FontAwesomeIcon icon={faDatabase} />
                              <span>{entry.totalSQLQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                        {entry.totalExcelQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total Excel Questions Solved"
                            arrow="true">
                            <div className="header gap-1">
                              <FontAwesomeIcon icon={faFileExcel} />
                              <span>{entry.totalExcelQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                      </div>
                    </li>
                  ))}
              </ul>

              <div className="flex gap-3 mt-4 justify-center items-center">
                <a href="/signup">Register</a>
                <a href="/Signin">Log In</a>
              </div>
            </div>
          </Drawer>
        </div>
        <nav
          className="dashboard-nav"
          style={{ top: "0px", height: "50px" }}>
          <img
            src={marmaLogo}
            alt="Marma AI"
            className="h-8 object-fit bg-transparent px-2 ml-1 "
          />
          <div className="ms-auto flex gap-4 px-4 items-center sm:hidden hidden md:flex">
            <div
              className="navigation flex items-center"
              style={{ marginRight: "0.75rem" }}>
              <ul className="metrics-container">
                {data &&
                  data.map((entry, index) => (
                    <li
                      key={index}
                      className="">
                      <div className="flex-row-container gap-[0.25rem]">
                        {entry.totalRewardPoints && (
                          <MaterialTooltip
                            placement="bottom"
                            arrow="true"
                            title="Total Reward Points">
                            <h6 className="header gap-1">
                              {" "}
                              <RewardCoinWithoutAnimation />
                              {entry.totalRewardPoints}
                            </h6>
                          </MaterialTooltip>
                        )}
                        {entry.totalQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total Questions Solved"
                            arrow="true">
                            <div className="header gap-1">
                              <ShowChartRounded />
                              <span>{entry.totalQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                        {entry.totalPythonQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total Python Questions Solved"
                            arrow="true">
                            <div className="header gap-1">
                              <CodeRounded />
                              <span>{entry.totalPythonQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                        {entry.totalSQLQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total SQL Questions Solved"
                            arrow="true">
                            <div className="header gap-1 flex-row-container">
                              <FontAwesomeIcon icon={faDatabase} />
                              <span>{entry.totalSQLQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                        {entry.totalExcelQuestionsSolved > 0 && (
                          <MaterialTooltip
                            placement="bottom"
                            title="Total Excel Questions Solved"
                            arrow="true">
                            <div className="header gap-1">
                              <FontAwesomeIcon icon={faFileExcel} />
                              <span>{entry.totalExcelQuestionsSolved}</span>
                            </div>
                          </MaterialTooltip>
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <a href="/signup">Register</a>
            <a href="/Signin">Log In</a>
          </div>
          <div className="flex sm:flex ms-auto md:hidden px-4">
            <Menu onClick={toggleDrawer(true)} />
          </div>
        </nav>
        <nav className="flex py-4 items-center flex-col sm:flex-col md:flex-row">
          <div
            className="px-4 flex gap-3"
            style={{ width: "auto" }}>
            <select
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              className="drop-down-container interval">
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </select>
            <select
              value={metric}
              onChange={(e) => setMetric(e.target.value)}
              className="drop-down-container interval">
              <option value="rewardPoints">Rewards</option>
              <option value="questionsSolved">Solved</option>
            </select>
            <button
              onClick={() => {
                takeScreenshot(document.body)
              }}
              className="bg-gray-200 p-2 rounded-full bg-opacity-10 hover:bg-amber-400 hover:bg-opacity-50 transition-all text-gray-400 hover:text-gray-200">
              <ScreenshotMonitorOutlined />
            </button>
          </div>
          {data && (
            <div className="m-0 sm:m-0 md:ms-auto mt-4 sm:mt-4 px-4">
              <p className="text-sm">
                <span className="font-bold">User Name:</span> {data[0].name}
              </p>
            </div>
          )}
          {/* <div
            className="nav-rewards-container"
            style={{ width: "40%", justifyContent: "space-evenly" }}>
            <button
              className={`sw-analysis-btn ${active === "schart" ? "active-btn" : ""}`}
              value="schart"
              onClick={(e) => setActive(e.target.value)}>
              Strength Analysis
            </button>
            <button
              className={`sw-analysis-btn ${active === "fchart" ? "active-btn" : ""}`}
              value="fchart"
              onClick={(e) => setActive(e.target.value)}>
              Frequency Chart
            </button>
          </div> */}
        </nav>

        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 px-4 min-h-[calc(100dvh-120px)]">
          <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px]">
            <DashboardStackedBarChart
              interval={interval}
              metric={metric}
              forPublic={true}
              handleBarChartData={(data) => setBarChartData(data)}
            />
          </div>


          <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[350px] overflow-hidden">
            <div className="mb-3 flex gap-4 w-max">
              <Button
                variant="outlined"
                size="small"
                className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${!isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
                onClick={() => setIsSubmissionChartVisible(false)}>
                Overall Progress
              </Button>

              <Button
                variant="outlined"
                size="small"
                className={`!border-0 !shadow-none !rounded-none !text-gray-200 !capitalize ${isSubmissionChartVisible ? "!border-b !border-gray-200 !bg-transparent" : "!border-transparent !text-gray-400"}`}
                onClick={() => setIsSubmissionChartVisible(true)}>
                Submissions
              </Button>
            </div>
            {isSubmissionChartVisible ? (
              <HeatMapChart data={barChartData} />
            ) : (
              <DashboardPieChart
                metric={metric}
                forPublic={true}
              />
            )}
          </div>

          <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px] overflow-hidden">
            <SWAccuracyChart forPublic={true} />
          </div>

          <div className="text-gray-300 bg-gray-700 bg-opacity-20 rounded-lg h-[360px] overflow-hidden">
            <Fchart
              userRewardPoints={(data) => setRewardPoints(data)}
              currUserData={handleUserData}
              forPublic={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicDashboard
