import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Filter from "../assets/filter"
import Modal from "../assets/modal"
import { SUBJECTS } from "../utils/constants"
import { Button, Tooltip } from "@mui/material"
import { marmaOnlyLogo, titleBarLogo } from "../assets/image_files"
import { useSelector, useDispatch } from "react-redux"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import { fetchArenasList, fetchCurrentArenaData } from "../redux/actions/arena"
import Loader from "../assets/loader"

import SubdirectoryArrowRightRoundedIcon from "@mui/icons-material/SubdirectoryArrowRightRounded"
import WestSharpIcon from "@mui/icons-material/WestSharp"
import EastSharpIcon from "@mui/icons-material/EastSharp"
import Badges from "../assets/badges"
import { Link, useParams, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Sidebar from "../assets/arena-question-list"
import RewardCoinWithoutAnimation from "./RewardPoint/RewardCoinWithoutAnimation"

const currQuestionId = window.location.href.split("?questionId=")[1]

const useStyles = makeStyles((theme) => ({
  search: {
    color: "#fefefe",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "auto",
    flexGrow: 1,
  },
  filterContainer: {
    position: "absolute",
    zIndex: "999",
    backgroundColor: "#232627",
    border: "1px solid #FEFEFE1A",
    borderRadius: "4px",
    width: "400px",
    height: "230px",
    color: "#fefefe",
    top: "2.75rem",
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    padding: "8px",
  },
  contentArea: {
    overflowY: "auto", // Make only this area scrollable
    flexGrow: 1, // Allow it to take up available space
  },
  arenaItem: {
    marginBottom: "10px",
    marginRight: "20px",
  },
  roadmapItem: {
    cursor: "pointer",
    marginBottom: "10px",
    marginRight: "20px",
  },

  subjectSelect: {
    minWidth: "120px",
    color: "#f2f2f2",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D1D1",
      },
      "&:hover fieldset": {
        borderColor: "#D1D1D1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D1D1D1",
      },
    },
  },
  headerDropdownContainer: {
    position: "absolute",
    zIndex: "999",
    top: "3.125rem",
    right: "0.625rem",
    backgroundColor: "#232627",
    border: "1px solid #FEFEFE1A",
    borderRadius: "4px",
    padding: "1rem",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    overflowY: "auto",
    color: "#fefefe",
    marginTop: "1px",
    fontSize: "14px",
  },
  optionStyle: {
    cursor: "pointer",
    margin: 0,
    padding: "0.275rem",
    fontWeight: "500",
    fontSize: "0.875rem",
  },
  arenaNameStyle: {
    color: "#D6EAF8",
    cursor: "pointer",
    margin: 0,
    padding: "0.2rem",
  },
  tab: {
    cursor: "pointer",
    padding: "0.2rem",
    borderRadius: "8px",
    fontWeight: "bold",
    backgroundColor: "#343839",
    fontSize: "0.75rem",
    margin: "3px",
  },
  activeTab: {
    cursor: "pointer",
    padding: "0.2rem",
    borderRadius: "8px",
    fontWeight: "bold",
    backgroundColor: "#343839",
    fontSize: "0.75rem",
    margin: "3px",
    backgroundColor: "#141718",
    color: "#21618C",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: "0.275rem",
    borderBottom: "1px solid #141718",
    marginLeft: "2px",
    marginRight: "2px",
  },
  roadmapList: {
    display: "none", // hide roadmaps by default
  },
  roadmapListOpen: {
    display: "block",
    transition: "max-height 0.5s ease-in-out",

    overflowY: "auto",
  },
}))

const filterButtonStyle = {
  padding: "0.2rem",
  border: "1px solid #FEFEFE1A",
  cursor: "pointer",
  backgroundColor: "#232627",
  color: "white",
}
const FilterContainer = ({ enrolledArenas }) => {
  const classes = useStyles()
  const [openRoadmaps, setOpenRoadmaps] = useState({}) // store open roadmaps

  const handleArenaClick = (arenaId) => {
    setOpenRoadmaps((prevOpenRoadmaps) => ({
      ...prevOpenRoadmaps,
      [arenaId]: !prevOpenRoadmaps[arenaId],
    }))
  }

  return (
    <div className={classes.filterContainer}>
      {enrolledArenas.map((arena) => (
        <div
          key={arena._id}
          className={classes.arenaNameStyle}
          onClick={() => handleArenaClick(arena._id)}>
          <h4 style={{ color: "#fefefe", fontWeight: "bold" }}>{arena.name}</h4>
          <ul className={`${classes.roadmapList} ${openRoadmaps[arena._id] ? classes.roadmapListOpen : ""}`}>
            {arena.roadmaps.map((roadmap) => (
              <li
                key={roadmap.id}
                className={classes.optionStyle}>
                <SubdirectoryArrowRightRoundedIcon /> {roadmap.name}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

const ArenaQuestionHeader = ({ techStacks, levels, primaryTool, selectedTechStack, selectedLevel, onChangeSubject, onChangeTechStack, onChangeLevel, onSubmit, isQuestionFetched, getQuestionMetaData, questionMeta, resetComponent }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("")
  const [submittedKey, onSetSubKey] = useState("")
  const [pendingTechStack, setPendingTechStack] = useState("")
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [userSearch, setSearch] = useState("")
  const classes = useStyles()
  const [prevQue, setPrevQue] = useState(-1)
  const [historyVisible, setHistory] = useState(false)
  const [showDropDown, setShowDropdown] = useState(false)
  const { name, roadmap } = useParams()
  const [activeTab, setActiveTab] = useState("arenas")
  const dispatch = useDispatch()

  const reduxUser = useSelector((state) => state.user.userDetails)
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const enrolledArenas = useSelector((state) => state.arena.enrolledArenas)
  const currentArena = useSelector((state) => state.arena.currentArena)
  const [currentRoadmap, setCurrentRoadmap] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (Object.keys(userMetrics).length === 0) dispatch(fetchUserMetrics())
    if (enrolledArenas.length === 0) dispatch(fetchArenasList())
    if (currentArena.length === 0) dispatch(fetchCurrentArenaData(name))
    if (currentArena.length !== 0) {
      const filteredRoadmap = currentArena.roadmaps.filter((roadmapItem) => roadmapItem.name === decodeURIComponent(roadmap))[0]
      setCurrentRoadmap(filteredRoadmap)
    }
  }, [userMetrics, currentArena, name])

  const confirmUpdate = async (confirm) => {
    setShowConfirmationModal(false)
    if (confirm) {
      if (submittedKey) {
        onSubmit(submittedKey)
        onSetSubKey("")
      }
      if (pendingTechStack) {
        onChangeTechStack(pendingTechStack)
        setPendingTechStack("")
      }
    } else {
      onSetSubKey("")
      setPendingTechStack("")
    }
  }

  const handleSubjectChange = (event) => {
    if (event.target.value !== primaryTool) onChangeSubject(event.target.value)
  }

  const logout = () => {
    localStorage.removeItem("usertoken")
    localStorage.removeItem("hasShownFreeArenaPopup")
    localStorage.removeItem("refreshToken")
    window.location.reload()
  }

  const switchQuestion = (direction) => {
    return getQuestionMetaData(direction === "next", null, direction === "prev")
    /*const { questionStatements } = currentRoadmap
    const cqid = currQuestionId
    const currQueIndex = questionStatements.findIndex((quesItem) => quesItem.questionId === cqid)

    if (direction === "next" && currQueIndex === questionStatements.length - 1) {
      toast.info("You've reached the end of this roadmap")
    } else if (direction === "prev" && currQueIndex === 0) {
      toast.info("You are in the initial stage of this roadmap")
    } else {
      const newIndex = currQueIndex + (direction === "next" ? 1 : -1)
      getQuestionMetaData(false, questionStatements[newIndex].questionId, true)
    } */
  }

  const switchNext = () => switchQuestion("next")
  const switchPrevious = () => switchQuestion("prev")

  const handleQuestionsHistory = (questionId) => {
    getQuestionMetaData(false, questionId, false)
  }

  if (!currentRoadmap) return <Loader />

  return showConfirmationModal ? (
    <Modal
      isOpen={showConfirmationModal}
      onClose={() => setShowConfirmationModal(false)}
      title="Confirm Action">
      <p className='text-gray-400 text-sm mt-2'>{confirmationModalMessage}</p>
      <div className='flex gap-2 mt-4'>
        <Button variant='contained' size='small' className='!shadow-none !bg-gray-600 !text-gray-200 !border-0' onClick={() => confirmUpdate(true)}>Yes</Button>
        <Button variant='outlined' size='small' className='!shadow-none !text-gray-200 !border !border-gray-600' onClick={() => confirmUpdate(false)}>No</Button>
      </div>
    </Modal>
  ) : (
    <>
      <div className="flex flex-col h-[90px] fixed top-0  bg-[#232627]  w-full z-[999]">
        <div className="question-page-links flex flex-row justify-between items-center px-5 my-[0.125rem]">
          <div className="flex flex-row items-center justify-start">
            <img
              src={marmaOnlyLogo}
              alt="Marma AI"
              className="h-6 object-fit bg-transparent px-2 m-1"
              onClick={() => navigate("/home")}
            />
            <Tooltip
              title="Switch to Previous Question"
              placement="bottom-start">
              <button
                onClick={switchPrevious}
                disabled={prevQue === 0}>
                {" "}
                <WestSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />
              </button>
            </Tooltip>
            <Tooltip
              title="Switch to Next Question"
              placement="bottom-start">
              <button onClick={switchNext}>
                {" "}
                <EastSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />{" "}
              </button>
            </Tooltip>
          </div>
          <div className={classes.search}>
            <div className="w-[40%] bg-[#343839] h-[1.75rem] rounded-full flex flex-row items-center overflow-hidden">

            <div className="flex"><span className="relative inline-block overflow-hidden rounded-full p-[1px]"><span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#a9a9a9_0%,#0c0c0c_50%,#a9a9a9_100%)] dark:bg-[conic-gradient(from_90deg_at_50%_50%,#171717_0%,#737373_50%,#171717_100%)]"></span><div className="inline-flex h-full w-full cursor-default justify-center rounded-full bg-white px-3 py-1 text-xs font-medium leading-5 text-slate-600 backdrop-blur-xl dark:bg-gray-700 !bg-opacity-20 dark:text-slate-200">Arena</div></span></div>

              <input
                type="search"
                value={userSearch}
                onChange={(e) => setSearch(e.target.value)}
                className="bg-[#343839] text-start w-full ms-2 focus:outline-none placeholder-opacity-75"
                placeholder={`You're in ${name} - ${roadmap}`}
                onBlur={()=> setIsFilterVisible(false)}
                onFocus={() => setIsFilterVisible(!isFilterVisible)}
              />
            </div>
            {isFilterVisible && <FilterContainer enrolledArenas={enrolledArenas} />}
          </div>
          <div
            className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-0.5 rounded flex gap-3 justify-between items-center cursor-default"
            style={{ width: "auto" }}>
            <Tooltip
                placement="bottom-start"
                arrow
                title="Reward Points">
                {userMetrics ? (
                  <p className="inline-flex text-sm gap-1 justify-center items-center">
                    {" "}
                    <RewardCoinWithoutAnimation/>
                    {userMetrics.totalRewardPoints}
                  </p>
                ) : (
                  ""
                )}
              </Tooltip>
              {userMetrics?.badges?.length !== 0 && <Badges badges={userMetrics?.badges} />}
          </div>
          <nav className="navigation flex items-center w-auto">
            <Link to="/question">Free Style</Link>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/arenas">Arenas</Link>

            {reduxUser ? (
              <button
                onClick={() => setShowDropdown(!showDropDown)}
                className=" rounded-full bg-[#343839] font-bold mx-[0.4rem]">
                {" "}
                {reduxUser.name.split(" ")[0][0]}
              </button>
            ) : (
              <button
                onClick={() => setShowDropdown(!showDropDown)}
                className=" rounded-full bg-[#343839] font-bold mx-[0.4rem]">
                {" "}
                <img
                  src={titleBarLogo}
                  alt="logout-dropdown"
                  className="h-7 bg-transparent rounded-full"
                />
              </button>
            )}
            {showDropDown && (
              <ul className={`${classes.headerDropdownContainer} list-none space-y-2`}>
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link
                    to="/faqs"
                    className="m-0 p-0">
                    FAQs
                  </Link>
                </li>
                <li>
                  <button
                    className="text-[#989898] hover:text-blue-500 text-[0.875rem] m-0 p-0"
                    onClick={logout}>
                    Logout
                  </button>{" "}
                  {/* Use a button for logout */}
                </li>
              </ul>
            )}
          </nav>
        </div>
        <div className="flex flex-row justify-start items-center px-5 my-2">
          <div className="px-2 py-1 text-gray-400">
            Selected Tool:
            <span className="text-gray-200 bg-gray-500 rounded-lg px-2.5 py-1.5 ms-2 bg-opacity-20">
            {primaryTool}
             </span>
          </div>
          

          {currentRoadmap && (
            <>
            <Tooltip
              placement="bottom"
              title="Click to See Questions"
              arrow>
              <Button
                onClick={() => setHistory(!historyVisible)}
                style={{ ...filterButtonStyle, fontFamily: "inherit" }}
                className="!text-sm !ms-auto !text-gray-400 !capitalize !px-2 !py-1.5">
                Roadmap
              </Button>
            </Tooltip>
              <Sidebar
                isOpen={historyVisible}
                onClose={() => setHistory(false)}
                questionStatements={currentRoadmap.questionStatements}
                arenaName={name}
                roadmapName={roadmap}
                handleQuestionHistory={handleQuestionsHistory}
                currQuestionId={currQuestionId}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ArenaQuestionHeader
