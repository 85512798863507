import { getQuestionHistoryList } from "../../utils/apis"

export const setQuestionHistory = (questionHistory, lastUpdated) => {
  return {
    type: "SET_QUESTION_HISTORY",
    payload: {
      questionHistory,
      lastUpdated,
    },
  }
}

export const fetchQuestionHistory = () => {
  return async (dispatch) => {
    try {
      // Fetch all questions on initial load (no lastUpdated)
      const response = await getQuestionHistoryList({ lastUpdated: null })
      if (response.status === 200) {
        console.log({ response })
        dispatch(setQuestionHistory(response.data, response.lastUpdated))
      }
    } catch (error) {
      console.error("Error fetching question history", error)
    }
  }
}

export const fetchUpdatedQuestionHistory = (lastUpdated = null) => {
  return async (dispatch) => {
    try {
      // Fetch updated/new questions based on lastUpdated timestamp
      const response = await getQuestionHistoryList({ lastUpdated })
      if (response.status === 200) {
        dispatch(setQuestionHistory(response.data, response.lastUpdated))
      }
    } catch (error) {
      console.error("Error fetching updated question history", error)
    }
  }
}
