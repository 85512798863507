import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, defs, linearGradient, stop, Legend } from 'recharts';
import Dropdown from '../ui/Dropdown';
import { MenuItem } from '@mui/material';
import { CustomLegend, CustomToolTip, CustomXAxisTick } from '../../utils/graphCustomeComponent';


const NewBarChart = ({ data }) => {

    const TOOLS = ["All", "Python", "SQL", "Excel"];
    const [selectedTool, setSelectedTool] = useState("All");
    const [aggregatedData, setAggregatedData] = useState([])

    const aggregateData = (data, subject) => {
        const aggregated = {};

        data.forEach(entry => {
            const levels = entry[`${subject}_levels`];
            Object.keys(levels).forEach(level => {
                if (!aggregated[level]) {
                    aggregated[level] = 0;
                }
                aggregated[level] += levels[level];
            });
        });

        return Object.keys(aggregated).map(level => ({
            level: "Level " + level,

            total: aggregated[level]
        }));
    };

    const aggregateAllSubjects = (data) => {
        const levelCounts = {};

        data.forEach(entry => {
            for (const subject of Object.keys(entry)) {
                if (subject === 'name') continue;

                const questions = entry[subject];

                for (const [level, count] of Object.entries(questions)) {
                    if (levelCounts[level]) {
                        levelCounts[level] += parseInt(count, 10);
                    } else {
                        levelCounts[level] = parseInt(count, 10);
                    }
                }
            }
        });

        const result = Object.entries(levelCounts).map(([level, total]) => ({
            level: "Level " + level,
            total
        }));

        return result;
    }

    useEffect(() => {
        console.log(selectedTool, "dekho")
        console.log(data, "data dekho")
        if (selectedTool !== "All") {
            setAggregatedData(aggregateData(data, selectedTool));
            console.log(aggregateData(data, selectedTool))
        } else {
            setAggregatedData(aggregateAllSubjects(data));
        }
    }, [selectedTool, data])
    return (
        <>
            <div className='mr-auto px-4 my-2'>
                <Dropdown
                    title={selectedTool}
                    children=
                    {TOOLS.map((tool, index) => (
                        <MenuItem
                            onClick={() => { setSelectedTool(tool) }}
                            className='!text-gray-200 !font-light !text-xs'
                            value={tool} key={tool}>{tool}</MenuItem>
                    ))}
                />
            </div>
            <div className='flex flex-col h-full justify-center w-full'>
                {
                    aggregatedData.length === 0 ?
                        <h1 className="text-sm text-center text-gray-400 justify-self-start">No Data to Display <br></br>Solve Questions to see your Progress!</h1> :
                        <ResponsiveContainer className="!p-0" width="100%" height={250}>
                            <BarChart width={600} height={300} data={aggregatedData}>
                                <defs>
                                    <linearGradient
                                        id="SQL"
                                        x1="0%"
                                        y1="0%"
                                        x2="100%"
                                        y2="100%">
                                        <stop
                                            offset="0%"
                                            style={{ stopColor: "#9f1239", stopOpacity: 1 }}
                                        />
                                        <stop
                                            offset="100%"
                                            style={{ stopColor: "#fb7185", stopOpacity: 1 }}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="Python"
                                        x1="0%"
                                        y1="0%"
                                        x2="100%"
                                        y2="100%">
                                        <stop
                                            offset="0%"
                                            style={{ stopColor: "#86198f", stopOpacity: 1 }}
                                        />
                                        <stop
                                            offset="100%"
                                            style={{ stopColor: "#e879f9", stopOpacity: 1 }}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="Excel"
                                        x1="0%"
                                        y1="0%"
                                        x2="100%"
                                        y2="100%">
                                        <stop
                                            offset="0%"
                                            style={{ stopColor: "#166534", stopOpacity: 1 }}
                                        />
                                        <stop
                                            offset="100%"
                                            style={{ stopColor: "#4ade80", stopOpacity: 1 }}
                                        />
                                    </linearGradient>
                                    <linearGradient
                                        id="colorAll"
                                        x1="0%"
                                        y1="0%"
                                        x2="0%"
                                        y2="100%">
                                        <stop
                                            offset="0%"
                                            style={{ stopColor: "#f41552", stopOpacity: 1 }}
                                        />
                                        <stop
                                            offset="35%"
                                            style={{ stopColor: "#b10fbe", stopOpacity: 1 }}
                                        />
                                        <stop
                                            offset="100%"
                                            style={{ stopColor: "#00cf4e", stopOpacity: 1 }}
                                        />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="level"
                                    axisLine={{ stroke: "#94a3b8" }}
                                    tickLine={{ stroke: "#94a3b8" }}
                                    tick={<CustomXAxisTick />}
                                    interval={0}
                                    angle={-20}
                                    textAnchor="middle"
                                    height={50}
                                    fontSize={12}
                                    dy={10}
                                />
                                <YAxis
                                    axisLine={{ stroke: "#94a3b8" }}
                                    tickLine={{ stroke: "#94a3b8" }}
                                    tick={{ fill: "#94a3b8" }}
                                    fontSize={12}
                                    label={{
                                        value: "Questions Solved",
                                        angle: -90,
                                        fill: "#94a3b8",
                                        position: "insideLeft",
                                        dy: 70,
                                        offset: 20
                                    }}
                                />
                                <Tooltip content={<CustomToolTip />} cursor={{ fill: '#374151', opacity: ".5" }} />
                                <Bar dataKey="total"
                                    fill={`url(#${selectedTool === "All" ? "colorAll" : selectedTool})`}
                                    radius={[8, 8, 0, 0]}
                                    barSize={16}
                                />
                                <Legend
                                    iconType="circle"
                                    content={<CustomLegend />}
                                    layout="vertical"
                                    verticalAlign="middle"
                                    align="right"
                                />
                            </BarChart>
                        </ResponsiveContainer>
                }
            </div>
        </>
    )
}


export default NewBarChart;
