import React, { useState, useEffect } from "react"
import { createOrder, verifyOrder } from "../utils/apis"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { fetchUserDetails } from "../redux/actions/user"
import { useNavigate } from "react-router-dom"
import { toastConfig } from "../utils/constants"

const RazorpayPaymentComponent = ({ tier, user, onDismiss }) => {
  console.log("RazorpayPaymentComponent:", tier, user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    paymentHandler()
  }, [])

  const loadRazorpayScript = (order) => {
    var options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: (order.amount * 100).toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: order.currency,
      name: process.env.REACT_APP_COMPANY_NAME, //your business name
      // description: "Test Transaction",
      image: process.env.REACT_APP_COMPANY_LOGO,
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
          subscriptionId: order.subsId,
          orderId: order.id,
          status: "SUCCESS",
        }

        const validateOrder = await verifyOrder(body)
        if (validateOrder && validateOrder.status === 200) {
          navigate("/question")
        } else {
          toast.error("Some error in payment process, please contact admin")
        }
      },
      modal: {
        confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
        // This function is executed when checkout modal is closed
        // There can be 3 reasons when this modal is closed.
        ondismiss: async (reason) => {
          const { reason: paymentReason, field, step, code } = reason && reason.error ? reason.error : {}
          // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
          if (reason === undefined) {
            console.log("cancelled")
            navigate(-1)
            toast.error("Payment cancelled", toastConfig)
          }
          // Reason 2 - When modal is auto closed because of time out
          else if (reason === "timeout") {
            console.log("timedout")
            navigate(-1)
            toast.error("Payment timed out", toastConfig)
          }
          // Reason 3 - When payment gets failed.
          else {
            console.log("failed")
            navigate(-1)
            toast.error("Payment failed", toastConfig)
          }
          onDismiss()
        },
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: user.name, //your customer's name
        email: user.email,
        contact: user.mobile, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Marma.ai",
      },
      theme: {
        color: "#3399cc",
      },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on("payment.failed", async function (response) {
      console.log(response)
      const body = {
        ...response,
        subscriptionId: order.subsId,
        status: "FAILURE",
      }
      toast.error(response.error.description || "Some error in payment process, please try again")
    })
    rzp1.on("payment.dismissed", function (event) {
      window.location.reload()
    })

    rzp1.open()
  }

  const paymentHandler = async () => {
    if (!tier || !user) return

    try {
      let order = await createOrder({ tier, gateway: "RAZORPAY" })
      if (order && order.status === 200 && order.data) {
        loadRazorpayScript(order.data)
      } else {
        toast.error("Some error in payment process, please contact admin")
      }
    } catch (error) {
      console.log(error)
      toast.error("Error subscribing")
    }
  }
  return null
}

export default RazorpayPaymentComponent
