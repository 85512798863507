import React, { useState, useEffect } from "react"
import "chart.js/auto"
import { XAxis, YAxis, Tooltip, Cell, Legend, ResponsiveContainer, ScatterChart, Scatter, ReferenceArea, CartesianGrid } from "recharts"
import Loader from "./loader"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import { userperformanceData, userarenaperformanceData } from "../utils/apis"
import { getUniqueId } from "../utils/common-function"
import { Button } from "@mui/material"

const SWAccuracyChart = ({ forPublic, filterParams }) => {
  const [userMetricsData, setUserMetricsData] = useState()
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const dispatch = useDispatch()
  const [selectedTech, setSelectedTech] = useState("SQL")

  const SWChartCustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#343839",
            padding: "0.5rem",
            border: "0.08rem solid #fefefe1a",
            color: "white",
            borderRadius: "0.5rem",
          }}>
          <p>{`Function: ${data.name}`}</p>
          <p>{`Total Runs: ${data.totalRuns}`}</p>
          <p>{`Total Rewards: ${data.rewards}`}</p>
          <p>{`Total Correctly Submitted Solutions: ${data.totalCorrectlySubmittedSol}`}</p>
          <p>{`Accuracy: ${data.accuracy.toFixed(2)}%`}</p>
        </div>
      )
    }
    return null
  }

  useEffect(() => {
    const getData = async () => {
      if (!forPublic) {
        if (filterParams.filterType === "Free Style") {
          dispatch(fetchUserMetrics())
          setUserMetricsData(userMetrics)
          if (!userMetrics) toast.error("Unable to Fetch Data")
        } else {
          const uniqueId = forPublic ? getUniqueId(window.location.href) : null
          const responseData = await userarenaperformanceData(uniqueId, filterParams)
          if (!responseData) toast.error("Unable to Fetch Data")
          setUserMetricsData(responseData.data)
        }
      } else {
        const uniqueId = getUniqueId(window.location.href)
        const responseData = await userperformanceData(uniqueId, filterParams)
        if (!responseData) toast.error("Unable to Fetch Data")
        setUserMetricsData(responseData.data)
      }
    }
    getData()
  }, [filterParams])

  const techData = {
    SQL: userMetricsData?.SQLFunctions ?? [],
    Excel: userMetricsData?.ExcelFunctions ?? [],
    Python: userMetricsData?.PythonFunctions ?? [],
  }

  const formattedData = techData[selectedTech]?.map((func) => ({
    name: func.name,
    totalRuns: func.performance.totalRuns,
    totalCorrectlySubmittedSol: func.performance.totalCorrectlySubmittedSol,
    rewards: func.performance.totalRewards,
    accuracy: (func.performance.totalCorrectlySubmittedSol / func.performance.totalRuns) * 100,
  }))

  
  const maxX = Math.ceil(Math.max(...formattedData.map((item) => item.accuracy)))
  const maxY = Math.ceil(Math.max(...formattedData.map((item) => item.rewards)))
  
  if (!userMetricsData) return <Loader />
  
  return (
    <div className="sw-container h-full">
      <h6 className="text-center text-xl font-semibold p-4"> Functional Strength Analysis</h6>
      <div className="flex gap-4 mb-4 px-4">
        <Button
          size="small"
          variant={`${selectedTech === "SQL" ? "contained" : "outlined"}`}
          className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedTech === "SQL" && "!bg-gray-600"}`}
          onClick={(e) => setSelectedTech("SQL")}>
          SQL
        </Button>
        <Button
          size="small"
          variant={`${selectedTech === "Python" ? "contained" : "outlined"}`}
          className={` !shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedTech === "Python" && "!bg-gray-600"}`}
          onClick={(e) => setSelectedTech("Python")}>
          Python
        </Button>
        <Button
          size="small"
          variant={`${selectedTech === "Excel" ? "contained" : "outlined"}`}
          className={`!shadow-none !text-sm !capitalize !border !border-gray-600 !text-gray-200 ${selectedTech === "Excel" && "!bg-gray-600"}`}
          onClick={(e) => setSelectedTech("Excel")}>
          Excel
        </Button>
      </div>
      {
        formattedData.length === 0 ?
          <div className="h-full p-4">
            <h1 className="text-center h-full flex justify-center items-center text-sm text-gray-400">No Data to Display <br></br>Solve Questions to track your performance across different functions & Techstacks!</h1>
          </div> :
          <ResponsiveContainer
            width="85%"
            height={250}>
            <ScatterChart
              width={730}
              height={250}
              margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
              <defs>
                <linearGradient
                  id="Triangle"
                  x1="0"
                  y1="1"
                  x2="2"
                  y2="0">
                  <stop
                    offset="0%"
                    stopColor="#dc2626"
                    stopOpacity={.3}
                  />
                  <stop
                    offset="100%"
                    stopColor="#4eff00"
                    stopOpacity={.5}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                type="number"
                dataKey="accuracy"
                name="Accuracy"
                unit="%"
                label={{ value: "Accuracy (%)", position: "bottom", fill: "#94a3b8" }}
                axisLine={{ stroke: "#94a3b8" }}
                tickLine={{ stroke: "#94a3b8" }}
                tick={{ fill: "#94a3b8" }}
                domain={[0, maxX]}
              />
              <YAxis
                axisLine={{ stroke: "#94a3b8" }}
                tickLine={{ stroke: "#94a3b8" }}
                tick={{ fill: "#94a3b8" }}
                type="number"
                dataKey="rewards"
                name="Rewards"
                label={{
                  value: "Reward Points",
                  angle: -90,
                  fill: "#94a3b8",
                  position: "insideLeft",
                  dy: 60,
                  offset: 10
                }}
                domain={[0, maxY]}
              />
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                content={<SWChartCustomTooltip />}
              />
              <Legend wrapperStyle={{ paddingTop: "1.8rem", fontWeight: "600" }} />
              <ReferenceArea
                x1={0}
                x2={maxX}
                y1={0}
                y2={maxY}
                fill="url(#Triangle)"
                fillOpacity={0.5}
              />

              <Scatter
                name={`Functions in ${selectedTech}`}
                data={formattedData}
                fill="#9ca3af">
                {formattedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill="#fee2e2"
                    size={80}
                  />
                ))}
              </Scatter>
            </ScatterChart>
            {/*<AreaChart
            data={formattedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
             <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient> 
          </defs>
            <XAxis dataKey="accuracy" />
            <YAxis label={{ value: 'Rewarard Points', angle: -90, position: 'insideLeft' }} dataKey="accuracy"/>
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area type="monotone" dataKey="accuracy" stroke="#8884d8" activeDot={{ r: 8 }} fillOpacity={1} fill="url(#colorUv)" />
          </AreaChart> */}
          </ResponsiveContainer>
      }
    </div>
  )
}

export default SWAccuracyChart
