const initialState = {
  barChartData: [],
  pieChartData: [],
}

const userProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        barChartData: action.payload.data,
        pieChartData: action.payload.pieChartData,
      }
    default:
      return state
  }
}

export default userProgressReducer
